import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from '@app/admin/admin.service';
import { ExportService } from '@app/exportservice/export.service';
import { convertDate } from '@app/finance/finance-utilities';
import { TableColumn } from '@app/shared/table/TableColumn';
import { UploadComponent } from '../upload/upload.component';
declare var $: any;

@Component({
  selector: 'app-candidatetable',
  templateUrl: './candidatetable.component.html',
  styleUrls: ['./candidatetable.component.css'],
})
export class CandidatetableComponent implements OnInit {
  @Input() dataSource: any[];

  @Input() tableColumns: TableColumn[] = [];
  @Input() tableActions = [];
  @Input() rowActionCallBacks = {};
  @Input() tabelActionCallBacks = {};
  @Input() toggel = {};
  @Input() toogleEmitter;
  @Input() isModal: boolean = false;
  @Input() radioButtonCallBack: boolean = false;
  @Input() selectedItems = [];
  @Input() title: string = 'Candidates';
  @Output() tableDateRangeEmitter: EventEmitter<any> = new EventEmitter();
  filterAttributes = [
    {
      dataKeyName: 'statusKey',
      name: 'Status',
      type: 'list',
      data: ['Active', 'Inactive', 'Deleted', 'Archived'],
    },
    {
      dataKeyName: 'full_name',
      name: 'Name',
      type: 'text',
      data: [],
    },
    {
      dataKeyName: 'username',
      name: 'Username',
      type: 'text',
      data: [],
    },
    {
      dataKeyName: 'addedOn',
      name: 'Added On',
      type: 'date',
      data: [],
    },
    {
      dataKeyName: 'custom_attrs',
      name: 'Attributes',
      type: 'text',
      data: [],
    },
    {
      dataKeyName: 'all',
      name: 'All Text',
      type: 'all',
      data: [],
    },
  ];

  dateRangeDetails = {
    isSingle: true,
    startDataKey: 'addedOn',
  };

  constructor(
    private route: Router,
    private exportService: ExportService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private adminSrvc: AdminService
  ) {}

  ngOnInit(): void {}

  rowAction(event: any) {
    this.rowActionCallBacks[event.rowName](event, event.rowName);
  }

  dateFilterChange(dateRange: any) {
    console.log(dateRange, "====date")
    this.tableDateRangeEmitter.emit(dateRange);
  }

  tableAction(event: any) {
    if (event === 'download') this.exportToExcel();
    else if (event === 'upload') this.uploadCandidates();
    else this.tabelActionCallBacks[event](event, this.dataSource);
  }
  toggleEmitterEvent(row, checked) {
    this.toogleEmitter(row, checked);
  }

  exportToExcel() {
    const userOrgList = JSON.parse(localStorage.getItem('userOrgList'));
    const orgIdToNameMap = userOrgList.reduce((acc, org) => {
      acc[org.org_id] = org.org_name;
      return acc;
    }, {});

    const filterFieldsForEachCandidateInAList = this.dataSource.map((item) => {
    const candidate = { ...item };
      const formatDate = (datetime) => {
        if (!datetime) return null;
        const date = new Date(datetime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      candidate.created_datetime = formatDate(candidate.created_datetime);
      candidate.modified_datetime = formatDate(candidate.modified_datetime);

      candidate['organization_name'] =
        orgIdToNameMap[candidate.organization_id];
      candidate['is_archived'] = candidate['is_archived'] ? 'Yes' : 'No';
      candidate['is_deleted'] = candidate['is_deleted'] ? 'Yes' : 'No';
      candidate['is_active'] = candidate['is_active'] ? 'Yes' : 'No';
      candidate['status'] = candidate['status'] == 1 ? 'active' : 'inactive';
      delete candidate.organization_id;
       if (candidate.custom_attrs && Array.isArray(candidate.custom_attrs)) {
       candidate.custom_attrs = candidate.custom_attrs.map(attr => {
         console.log(attr)
         const key = attr.value;
         const value = attr.labelValue;
         return `${key}: ${value}`;
       }).join(", ");
  }
      return candidate;
    });
    const fileName = `Candidates_${convertDate()}_${new Date().getTime()}`;
    this.exportService.exportExcel(
      filterFieldsForEachCandidateInAList,
      fileName
    );
  }

  uploadCandidates() {
    const dialogRef = this.dialog.open(UploadComponent, {
      width: '100vw',
      maxHeight: '750px',
      minHeight: 'fit-content',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      data: { type: 'candidates' },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
