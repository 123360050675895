import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UtilService } from '@app/shared/services/util.service';
import { SettingsService } from '../settings.service';
import {
  HEADER_X_REFFERER,
  PREFERENCES,
} from '@app/shared/constants/application-constants';
import { Router } from '@angular/router';
import { getXReferer } from '@app/operation/project/project.constants';

export enum constants {
  'IN_APP_PUSH' = 'in_app_push',
  'IN_APP' = 'inAppPush',
}

export interface Preference {
  id: string;
  completed: boolean;
  subtasks?: Preference[];
}

export interface Service {
  service: string;
  preferences: Preference[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-notificationsettings',
  templateUrl: './notificationsettings.component.html',
  styleUrls: ['./notificationsettings.component.css'],
})
export class NotificationsettingsComponent implements OnInit, OnDestroy {
  servicesList = [];
  readonly constants: typeof constants = constants;
  preferences = PREFERENCES;
  preferencesList: any[] = [];
  allComplete: any = {};
  settings: any = [];
  serviceError: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private util: UtilService,
    private settingsService: SettingsService,
    private route: Router
  ) {}

  ngOnInit(): void {
    const x_referer = getXReferer(this.route.url)
    localStorage.setItem(HEADER_X_REFFERER, x_referer);
    this.getAllNotifications();
  }

  ngOnDestroy(): void {
    localStorage.setItem(HEADER_X_REFFERER, '');
  }

  getAllNotifications() {
    let resp = this.settingsService.getAllNotificationEvents();
    resp.subscribe(
      (data: any) => {
        let obj: any = {};
        const response = data.body.responseBody;
        const services = Object.keys(response).map((key) => {
          return response[key].map((item: any) => item.menu_name);
        });
        this.servicesList = [...new Set(services.flat(1))];
        Object.keys(response).forEach((key) => {
          response[key].forEach((item: any) => {
            if (obj?.[item.menu_name]) {
              this.preferences.forEach((preference) => {
                const index = obj[item.menu_name].preferences.findIndex(
                  (item: any) => item.id === preference
                );
                obj[item.menu_name].preferences[index].subtasks.push({
                  id: item.event_id,
                  description: item.description,
                  completed: false,
                });
              });
            } else {
              obj[item?.menu_name] = {
                service: item?.menu_name,
                preferences: [],
              };
              this.preferences.forEach((preference) => {
                obj[item.menu_name].preferences.push({
                  id: preference,
                  completed: false,
                  subtasks: [
                    {
                      id: item.event_id,
                      description: item.description,
                      completed: false,
                    },
                  ],
                });
              });
            }
          });
        });
        Object.keys(obj).forEach((item) => {
          this.preferencesList.push(obj[item]);
        });
        this.servicesList.forEach((service) => {
          let preferenceObj = {};
          this.preferences.forEach((preference) => {
            preferenceObj[preference] = false;
          });
          this.allComplete[service] = preferenceObj;
        });
        this.settingsService.getNotificationSettings().subscribe(
          (data: any) => {
            const response = data.body.responseBody;
            if (response?.length) {
              this.settings = response;
              response?.forEach((item: any) => {
                this.preferencesList.forEach((preference, index) => {
                  preference.preferences.forEach((i: any, pIndex: number) => {
                    i.subtasks.forEach((task: any, tIndex: number) => {
                      if (task.id == item.event_id) {
                        this.preferencesList[index].preferences[
                          pIndex
                        ].subtasks[tIndex].completed = item[i.id];
                      }
                    });
                  });
                });
              });
            }
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
          },
          (err: any) => {
            this.serviceError = this.util.getIntlErrorMessage(
              'NOTIFICATION_SETTING_UPDATE',
              err.responseHead.statusCode,
              err.responseHead.statusDesc
            );
            this.util.simpleDialog({
              title: 'Error',
              message: this.serviceError || err,
            });
          }
        );
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      },
      (err: any) => {
        this.serviceError = this.util.getIntlErrorMessage(
          'NOTIFICATION_SETTING_UPDATE',
          err.responseHead.statusCode,
          err.responseHead.statusDesc
        );
        this.util.simpleDialog({
          title: 'Error',
          message: this.serviceError || err,
        });
      }
    );
  }

  getRequestBody(id: string, item: any, event: any) {
    let obj: any = { eventId: item.id };
    this.preferences.forEach((preference) => {
      if (id === constants.IN_APP_PUSH && preference === id) {
        obj[constants.IN_APP] = item.completed;
      } else if (id === preference) {
        obj[id] = item.completed;
      } else {
        if (preference === constants.IN_APP_PUSH) {
          obj[constants.IN_APP] = event ? event.in_app_push : false;
        } else {
          obj[preference] = event ? event[preference] : false;
        }
      }
    });

    return obj;
  }

  updateAllComplete(id: string, item: any) {
    const event = this.settings.find(
      (setting: any) => setting.event_id === item.id
    );
    const data = this.getRequestBody(id, item, event);

    if (event) {
      this.settingsService.updateNotificationSettings(event.id, data).subscribe(
        (data: any) => {
          const response = data.body.responseBody;
          const responseHead = data.body.responseHead;
          this.getAllNotifications();
          const successMsg = responseHead
            ? this.util.getSuccessMessage(
                'NOTIFICATION_SETTING_UPDATE',
                responseHead.statusCode,
                responseHead.statusDesc
              )
            : responseHead;
          this.util.simpleDialog({
            title: 'Success',
            message: successMsg,
          });
        },
        (err: any) => {
          if (err.responseHead) {
            this.serviceError = this.util.getIntlErrorMessage(
              'NOTIFICATION_SETTING_UPDATE',
              err.responseHead.statusCode,
              err.responseHead.statusDesc
            );
            this.util.simpleDialog({
              title: 'Error',
              message: this.serviceError,
            });
          }
        }
      );
    } else {
      this.settingsService.saveNotificationSettings(data).subscribe(
        (data: any) => {
          const response = data.body.responseBody;
          const responseHead = data.body.responseHead;
          this.getAllNotifications();
          const successMsg = responseHead
            ? this.util.getSuccessMessage(
                'NOTIFICATION_SETTING_UPDATE',
                responseHead.statusCode,
                responseHead.statusDesc
              )
            : responseHead;
          this.util.simpleDialog({
            title: 'Success',
            message: successMsg,
          });
        },
        (err: any) => {
          if (err.responseHead) {
            this.serviceError = this.util.getIntlErrorMessage(
              'NOTIFICATION_SETTING_UPDATE',
              err.responseHead.statusCode,
              err.responseHead.statusDesc
            );
            this.util.simpleDialog({
              title: 'Error',
              message: this.serviceError,
            });
          }
        }
      );
    }
  }

  someComplete(service: string, id: string) {
    const index = this.preferencesList.findIndex(
      (item) => item.service == service
    );
    const subIndex = this.preferencesList[index].preferences.findIndex(
      (item: any) => item.id == id
    );
    if (this.preferencesList[index].preferences[subIndex].subtasks == null) {
      return false;
    }
    return (
      this.preferencesList[index].preferences[subIndex].subtasks.filter(
        (t: any) => t.completed
      ).length > 0 && !this.allComplete[service][id]
    );
  }

  setAll(completed: boolean, service: string, subitems: any) {
    const index = this.preferencesList.findIndex(
      (item) => item.service == service
    );
    const subIndex = this.preferencesList[index].preferences.findIndex(
      (item: any) => item.id == subitems.id
    );
    if (this.preferencesList[index].preferences[subIndex].subtasks == null) {
      return;
    }
    this.preferencesList[index].preferences[subIndex].subtasks.forEach(
      (t: any) => (t.completed = completed)
    );

    subitems.subtasks.forEach((task: any) => {
      this.updateAllComplete(subitems.id, task);
    });
  }
}
