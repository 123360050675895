// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*-------------------------------------------------------------------------------------------------*/

  DATE_FORMAT: 'DD-MMM-YYYY',
  LOCALE: 'en-IN',

  /*-------------------------------------------------------------------------------------------------*/
  DEFAULT_VISIBILITY_IDS: [8, 21],
  SUB_MENU_IDS: [19],
  EDITOR_API_KEY: 'rcs2ne9ddapicjwo91rxce0hzf4jmzfgb4rihlvodi3diojx',
  FRESHDESK_CHAT_KEY: '//in.fw-cdn.com/32049251/1090631.js',
  SENTRY_DSN:
    'https://c06b12f4e28f883a27aeee292019e436@o4505114550206464.ingest.us.sentry.io/4507661010206720',
  VERSION: 'V3.0.0.0',
  APP_ENVIRONMENT: "development",
  firebaseConfig: {
    apiKey: "AIzaSyBC0K0v7OSdJmw10XPLkSegjFO4VnZ2HI0",
  authDomain: "certiplate-v3-qa.firebaseapp.com",
  databaseURL: "https://certiplate-v3-qa-default-rtdb.firebaseio.com",
  projectId: "certiplate-v3-qa",
  storageBucket: "certiplate-v3-qa.appspot.com",
  messagingSenderId: "1034520711604",
  appId: "1:1034520711604:web:5d9a45458bdfc9fd1762e1",
  measurementId: "G-YH3N04T2K8"
  },
  vapidKey:
    'BOlK8v910B7EbKVtSt34yFXBbeBPulVlJxGDcni1vODaFIKmXD-IeAdJL5KSVG-MwTZke8-A2zFCFLZ2WSrKU3I',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
