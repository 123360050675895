import { Injectable, EventEmitter } from '@angular/core';
import { URL } from '@app/shared/constants/service-urls';
import { ApiService } from '@app/shared/services/api.service';
import { GLOBAL, UPDATE } from '../constants/application-constants';

@Injectable({ providedIn: 'root' })
export class AppNotificationsService {
  public menuEmitter: EventEmitter<boolean>;
  constructor(private api: ApiService) {
    this.menuEmitter = new EventEmitter<boolean>();
  }
  open(): void {
    console.log("inside open")
    this.menuEmitter.emit(true);
  }
  close(): void {
    console.log("inside close")
    this.menuEmitter.emit(false);
  }

  getNotifications(page:number, size: number) {
    const headers = {
      "x-referer": GLOBAL
    }
    return this.api.get(URL.NOTIFICATIONS.GET_ALL_NOTIFICATIONS,{"page":page,"size":size}, headers);
  }

  markAsRead(id?: number) {
    const headers = {
      "x-referer" : GLOBAL
    }
    if (id) {
      return this.api.put(`${URL.NOTIFICATIONS.MARK_AS_READ}/${id}`, undefined, headers)
    }
    return this.api.put(`${URL.NOTIFICATIONS.MARK_AS_READ}`, undefined, headers)
  }
}
