import { ASSESSMENTS } from "./application-constants";

export const fields = {
    COURSE_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'external_reference', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'course_group_id', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'course_subgroup_id', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'course_framework_id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
    ],
    BUSINESS_VERTICAL: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
    ],
    RATE_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'customer', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'service', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
    ],
    PROJECT: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'alias', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'short_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'tags', datatype: 'json', required: false, regex: null, dataDescription: 'json (Ex: {"Course": ["Test", "Test 3"]}', patternDescription: '' },
    ],
    USERS: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'full_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample)', patternDescription: '' },
        { field: 'phone', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 9999999999)', patternDescription: '' },
        { field: 'email', datatype: 'string', required: true, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, dataDescription: 'text (Ex: xyz@gmail.com)', patternDescription: 'text (Ex: xyz@gmail.com)' },
    ],
    CANDIDATE: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'full_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample)', patternDescription: '' },
        { field: 'pincode', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 560062)', patternDescription: '' },
        { field: 'district', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: Bengaluru)', patternDescription: '' },
        { field: 'state', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: Karnataka)', patternDescription: '' },
        { field: 'phone', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 9999999999)', patternDescription: '' },
        { field: 'email', datatype: 'string', required: false, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, dataDescription: 'text (Ex: xyz@gmail.com)', patternDescription: 'text (Ex: xyz@gmail.com)' },
    ],
    LEAD_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'business_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'spoc_ids', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'address', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'status_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'estimated_units', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'estimated_revenue', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'industry_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'pincode_id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'type_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'geography_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
    ],
    CUSTOMER_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'business_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'gstin', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'pan', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'primary_spoc_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'spoc_ids', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'address', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'logo_file_name', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'industry_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'type_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'status_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'pincode_id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'geography_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },

    ],

    ASSESSMENTS:[
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'title', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'instructions', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'start_datetime', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'end_datetime', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'tags', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'custom_attrs', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'rules', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'address_line1', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'address_line2', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'district', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'state', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'pincode', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},     
        { field: 'status', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'course_group_id', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'course_subgroup_id', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'course_id', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'course_version_id', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'pincode_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'geography_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'published_on', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
    ]

}

