import { Injectable } from '@angular/core';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';
import { GLOBAL, VIEW } from '../constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class GetAllService {
  constructor(private api: ApiService, private util: UtilService) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }
  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  getAll(module: any, data?: any) {
    var postData: APIRequest;
    var postURL = '/getall/' + module;
    switch (module) {
      case 'courseFrameworks':
      case 'indVerticals':
      case 'modules':
      case 'orgs':
      case 'statesAndDistricts':
        postData = {};
        break;
      case 'industryVertical':
      case 'businessVertical':
      case 'product':
      case 'courseGroup':
        postData = {
          requestBody: {
            organization_id: parseInt(this.orgID, 10),
          },
        };
        break;
      case 'courseSubGroup':
      case 'courseFrameworksByOrgId':
        postData = {
          requestBody: {
            organization_id: parseInt(this.orgID, 10),
          },
        };
        break;
      case 'admin':
        postData = {
          requestBody: {
            organization_id: data,
          },
        };
        break;
      case 'orgUsers':
      case 'salesLead':
      case 'userCategories':
        postData = {
          requestBody: {
            organization_id: parseInt(this.orgID, 10),
          },
        };
        break;
      default:
        break;
    }

    if (module === 'orgs') {
      var headers = {
        'x-referer': GLOBAL,
      };
      return this.api.post(postURL, postData, headers);
    }
    return this.api.post(postURL, postData);
  }

  getAdminRoles() {
    const headers = {
      'x-operation': VIEW,
      'x-referer': GLOBAL,
    };
    var postURL = '/getall/adminRoles';
    let postData = {
      requestBody: {
        organization_id: parseInt(this.orgID, 10),
      },
    };
    return this.api.post(postURL, postData, headers);
  }

  getUserRoles() {
    const headers = {
      'x-operation': VIEW,
    };
    return this.api.get('/users/roles', undefined, headers);
  }

  getAdmin(id) {
    const headers = {
      'x-operation': VIEW,
      "x-referer": GLOBAL
    };
    const postURL = '/getallAdmin';
    let postData = {
      requestBody: {
        orgID: parseInt(id, 10),
      },
    };
    return this.api.post(postURL, postData, headers);
  }
}
