import * as CryptoJS from 'crypto-js';
import * as Moment from 'moment';

import { ApiService } from '@app-shared/services/api.service';
import { EventBusService } from '@app-shared/services/event-bus.service';
import { EventData } from '@app-shared/services/event-class';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToWords } from 'to-words';
import { ToastrService } from 'ngx-toastr';
import { fields } from '../constants/upload-validation-types';
import { messages } from '@app-shared/constants/error-messages';
import { successMessages } from '@app-shared/constants/success-messages';

declare const window: any;
declare var $ :any;

interface DialogData{
  title: string,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  
  encKey: string = '75a7a847e3569085185c6ffdc9c3f3b433378c1d77e2b37a2b11a145f01a';
  clientIPAddr: string = 'x.x.x.x';
  clientGeoLocation: string = '0,0';
  showLoader: boolean = false;
  timeoutId: any;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private eventBusService: EventBusService,
    private toastr: ToastrService
  ) {}

  get clientBrowser(){
    const userAgent = window.navigator.userAgent.toLowerCase();
    const browser =
    userAgent.indexOf('edge') > -1 ? 'edge'
        : userAgent.indexOf('edg') > -1 ? 'chromium based edge'
        : userAgent.indexOf('opr') > -1 && !!window.opr ? 'opera'
        : userAgent.indexOf('chrome') > -1 && !!window.chrome ? 'chrome'
        : userAgent.indexOf('trident') > -1 ? 'ie'
        : userAgent.indexOf('firefox') > -1 ? 'firefox'
        : userAgent.indexOf('safari') > -1 ? 'safari'
        : 'unknown browser';

    return browser;
  }

  get jwt(){
    return localStorage.getItem('JwtToken');
  }

  getIntlErrorMessage(identifier: string, statusCode: string, statusDesc?: string): string {
    const lang = 'en';
    if (!messages[identifier] || !messages[identifier][statusCode] || !messages[identifier][statusCode][lang]){
      return statusDesc || '';
    }
    return messages[identifier][statusCode][lang];
  }

  errorNotification(errorMessage : string){
    this.simpleDialog({
      title: "error",
      message : errorMessage
    });
  }

  warningNotification(warningMessage : string){
    this.simpleDialog({
      title: "warning",
      message : warningMessage
    });
  }

  getSuccessMessage(identifier: string, statusCode: string, statusDesc: string): string {
    const lang = 'en';
    if (!successMessages[identifier] || !successMessages[identifier][statusCode] || !successMessages[identifier][statusCode][lang]){
      return statusDesc || '';
    }
    return successMessages[identifier][statusCode][lang];
  }

  encrypt(text): string {
    return CryptoJS.AES.encrypt(text, this.encKey).toString();
  }

  decrypt(text): string {
    return CryptoJS.AES.decrypt(text, this.encKey).toString(CryptoJS.enc.Utf8);
  }

  simpleDialog(data: DialogData) {
    switch (data?.title.toLowerCase()) {
      case "success":
      case "added":
      case "updated":
      case "deleted":      
        this.toastr.success(data?.message);
        break;
      
      case "error":
        this.toastr.error(data?.message);
        break;

      case "warning":
        this.toastr.warning(data?.message);
        break;

      default:
        break;
    }
  }

  async getFileFromUrl(url, name, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  generatePassword(passwordLength:number) {
    const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const symbols = ['!', '?', '@', '#'];

    const getRandom = array => array[Math.floor(Math.random() * array.length)];

    let finalCharacters = '';
    finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    finalCharacters = finalCharacters.concat(getRandom(numbers));
    finalCharacters = finalCharacters.concat(getRandom(symbols));

    for (let i = 1; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    return  finalCharacters.split('').sort(() => 0.5 - Math.random()).join('');
  }

  cancelTimeout() {
    clearTimeout(this.timeoutId);
  }

  setTokenExpiryPopup(token: any) {
    if (typeof this.timeoutId === 'number') {
      this.cancelTimeout();
    }
    const jwtToken = JSON.parse(atob(token.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now();
    this.timeoutId = setTimeout(() => {
      const errorMessage = this.getIntlErrorMessage('SESSION_EXPIRED', '9999');
      this.simpleDialog({
        title: 'Error',
        message: errorMessage,
      });
      this.eventBusService.emit(new EventData('logout', null));
      this.timeoutId = undefined;
      $('.modal').modal('hide');
    }, timeout);
  }

  getFormatedDate(inputDate: any, dateFormat: string) {
    const utcDate = new Date(inputDate);
    return Moment(utcDate).format(dateFormat);
  }
  
  getFeatureAccess(menus: any, menuName: string, pageName: string) {
    const permissionStatuses = {};

    for (let menu of menus) {
        if (menu.menu_name.toLowerCase() === menuName.toLowerCase() && menu.pages) {
            for (let page of menu.pages) {
                if (page.menu_name.toLowerCase() === pageName.toLowerCase() && page.features) {
                  let features = JSON.parse(page.features);
                    for (let featureKey in features) {
                        if (features.hasOwnProperty(featureKey)) {
                            let feature = features[featureKey];
                            permissionStatuses[feature.name] = typeof feature?.is_granted === 'boolean' ? feature?.is_granted : feature?.is_granted == "true" ? true: false;
                        }
                    }
                    return permissionStatuses;
                }
            }
        }
    }
    return null; 
}

getFeatureAccessV2(menus: any, menuName: string, pageName: string) {
  const permissionStatuses = {};
  for (let menu of menus) {
      if (menu.menu_name.toLowerCase() === menuName.toLowerCase() && menu.pages) {
          for (let page of menu.pages) {
              if (page.menu_name.replace(" ", "").toLowerCase() === pageName.replace(" ", "").toLowerCase() && page.features) {
                let features = JSON.parse(page.features);
                  for (let featureKey in features) {
                      if (features.hasOwnProperty(featureKey)) {
                          let feature = features[featureKey];
                          permissionStatuses[feature.name] = typeof feature?.is_granted === 'boolean' ? feature?.is_granted : feature?.is_granted == "true" ? true: false;
                      }
                  }
                  return permissionStatuses;
              }
          }
      }
  }
  return null; 
}

getPermissionByFeatureName(permissionName: string, permissionStatuses: any) {
    for (let key in permissionStatuses) {
        if (permissionStatuses.hasOwnProperty(key)) {
            if (permissionName == key) {
                return permissionStatuses[key];
            }
        }
    }
    return null;
}

  getCourseLabelName(labelId: number) {
    const courseFrameworkLabels = JSON.parse(this.decrypt(localStorage.getItem('course_framework_labels')));
    if (!courseFrameworkLabels || !courseFrameworkLabels.length) {
      return '';
    }
    const filteredData = courseFrameworkLabels.find((ele: any) => ele.label_type_id === labelId);
    if (filteredData && filteredData.label_name) {
      return filteredData.label_name;
    }
    return '';
  }

  getPluralString(name: string) {
    const specialPlurals = ['Class', 'Batch', 'Branch'];
    const [str1, str2] = name.split('/');
    let resultStr: string;
    if (str1 && specialPlurals.includes(str1)) {
      resultStr = `${str1}es`;
    } else {
      resultStr = `${str1}s`;
    }
    if (str2 && specialPlurals.includes(str2)) {
      resultStr += `/${str2}es`;
    } else if(str2) {
      resultStr += `/${str2}s`;
    }
    return resultStr;
  }

  getPluralCourseLabelName(labelId: number) {
    const courseFrameworkLabelsList = localStorage.getItem('course_framework_labels');
    if (!courseFrameworkLabelsList) {
      return '';
    }
    const courseFrameworkLabels = JSON.parse(this.decrypt(courseFrameworkLabelsList));
    if (!courseFrameworkLabels || !courseFrameworkLabels.length) {
      return '';
    }
    const filteredData = courseFrameworkLabels.find((ele: any) => ele.label_type_id === labelId);
    if (filteredData && filteredData.label_name) {
      return this.getPluralString(filteredData.label_name);
    }
    return '';
  }

  

  mceOptionConfig = {
    height: 200,
    menubar: false,
    plugins: 'print preview paste importcss searchreplace autolink autosave \
    save directionality code visualblocks visualchars fullscreen image link \
    tiny_mce_wiris \
    media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime \
    advlist lists wordcount imagetools textpattern \
    noneditable help charmap quickbars emoticons',

    external_plugins:  {tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js' },

    toolbar:
      'undo redo | preview | formatselect | bold italic backcolor | \
      tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry|\
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help | \
      link image | code | forecolor backcolor removeformat | \
      pagebreak | charmap emoticons | fullscreen  preview save print | \
      insertfile image media template link anchor codesample | ltr rtl'
  }

  mceNonEditorConfig = {
    height: 400,
    menubar: false,
    toolbar: false,
    selector: 'textarea',
    plugins: 'noneditable autoresize',
    noneditable_noneditable_class: 'mceNonEditable',
    readonly: true,
    setup: function(editor) {
      editor.on('keydown', function (event) {
        if (event.keyCode === 8 || event.keyCode === 46)  {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      });
    }
  }

  getCourseHeadingName(id: any) {
    let heading: any;
    switch(parseInt(id, 10)) {
      case 1:
        heading = 'QP';
        break;
      case 2:
        heading = 'Course';
        break;
      case 3:
        heading = 'Subject';
        break;
      case 4:
        heading = 'Subject';
        break;
      case 5:
        heading = 'Course';
        break;
      case 6:
        heading = 'Function';
        break;
      default:
        heading = 'Course';
    }
    return heading;
  }

  getModuleHeadingName(id: any) {
    let heading: any;
    switch(parseInt(id, 10)) {
      case 1:
        heading = 'NOS';
        break;
      case 2:
        heading = 'Module';
        break;
      case 3:
        heading = 'Chapter';
        break;
      case 4:
        heading = 'Chapter';
        break;
      case 5:
        heading = 'Module';
        break;
      default:
        heading = 'Module';
    }
    return heading;
  }

  getTopicHeadingName(id: any) {
    let heading: any;
    switch(parseInt(id, 10)) {
      case 1:
        heading = 'PC';
        break;
      case 2:
        heading = 'Topic';
        break;
      case 3:
        heading = 'Topic';
        break;
      case 4:
        heading = 'Topic';
        break;
      case 5:
        heading = 'Topic';
        break;
      default:
        heading = 'Topic';
    }
    return heading;
  }

  getFieldList(type: string) {
    return fields[type.toUpperCase()]
  }

  range = (start: number, end: number, step: number) => {
    return Array.from(Array.from(Array(Math.ceil((end-start)/step)).keys()), x => start+ x*step);
  }

  getFormattedNotifications(list: any[]) {
    if (list.length > 0) {
      const arr = list?.slice().sort(function (a: any, b: any): any {
        return +new Date(b?.created_datetime) - +new Date(a?.created_datetime)
      })

      let currentDate = Moment(new Date()).format('DD-MM-YYYY')
      let yesterdayDate = Moment().subtract(1, 'day').format('DD-MM-YYYY')

      const groups = arr.reduce((groups: any, listItem: any) => {
        let date = Moment(listItem.created_datetime.split('T')[0]).format('DD-MM-YYYY')
        if (date === currentDate) date = 'TODAY'
        else if (Moment(listItem.created_datetime).format('DD-MM-YYYY') === yesterdayDate)
          date = 'YESTERDAY'
        else date = 'OLDER'

        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(listItem)
        return groups
      }, {})

      return groups
    }
    return []
  }

  openNotificationDialog(title: string, message: string, customClass: string) {
    this.toastr.show(title, message, {
      positionClass: 'toast-bottom-right',
      toastClass: customClass,
      tapToDismiss: true,
      timeOut: 5000
    });
  }

  getApiUrl() {
    const apiUrls = {
      local: 'http://localhost:8080',
      'app-dev': 'https://api-dev.certiplate.com',
      'app2-dev': 'https://api2-dev.certiplate.com',
      'app-qa': 'https://api-qa.certiplate.com',
      'app-uat': 'https://api-uat.certiplate.com',
      prod: 'https://api.certiplate.com'  
    }
    let env: any;
    if (window.location.hostname === 'localhost') {
      env = 'local';
    } else {
      const environment = window.location.host.replace('.certiplate.com', '');
      env = ['app-dev', 'app2-dev', 'app-qa', 'app-uat'].includes(environment) ? environment : 'prod';
    }
    return apiUrls[env];
  }

  isJson(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  getToWordsConfig() {
    return new ToWords({
      localeCode: 'en-IN',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: { // can be used to override defaults for the selected locale
          name: 'Rupee',
          plural: 'Rupees',
          symbol: '₹',
          fractionalUnit: {
            name: 'Paisa',
            plural: 'Paise',
            symbol: '',
          },
        }
      }
    });
  }

  getBlankIndicesStartEndData(formValue) {
    const { actualText, possibleAnswers } = formValue;
    const blankIndicesStartEndData = [];
    const wordBlankArray = actualText.match(/[^[\]]+(?=])/g);
    if (wordBlankArray && wordBlankArray.length) {
      wordBlankArray.forEach((ele: any, index: any) => {
        const blankWord = `[${ele}]`;
        const startIndex = actualText.indexOf(blankWord);
        const endIndex = startIndex + blankWord.length - 1;
        const item = {
          start_index: startIndex,
          end_index: endIndex,
          blank_text: blankWord,
          ans: possibleAnswers[index]?.answers,
        };
        blankIndicesStartEndData.push(item);
      });
    }
    return JSON.stringify(blankIndicesStartEndData);
  }

  getBlankTextAnswerKeyData(formValue) {
  const { possibleAnswers } = formValue;
  const blankTextAnswerKeyData = [];
  if (possibleAnswers && possibleAnswers.length) {
    possibleAnswers.forEach((ele: any, index: number) => {
      const eleStr = `${index + 1}=>${ele.answers
        .replace(/[\n,]/g, '<br>')
        .replace(/ /g, '_')}`;
      blankTextAnswerKeyData.push(eleStr);
    });
  }
  return blankTextAnswerKeyData.join(',');
}


  getMatchingJson(formValue) {
    const { primaryList, secondaryList } = formValue;
    const matchResult = [];
    if (primaryList && primaryList.length) {
      const listValues = [];
      const attachments = [];
      const correctSequence = [];
      primaryList.forEach((ele: any, index: number) => {
        if (ele.value) {
        listValues.push(`${index + 1}=>${ele.value.replace(/ /g, '_').replace(/,/g, ' ')}`);
        }
        if (ele.attachment) {
          attachments.push(`${index + 1}=>${ele.attachment}`);
        }
        correctSequence.push(`${index + 1}=>${index + 1}`);
      });
      matchResult.push({
        matchId: primaryList[0]?.matchId || 0,
        listOrderId: 1,
        isPrimaryList: true,
        listValues: listValues.join(','),
        listAttachments: attachments.join(','),
        correctSequence: correctSequence.join(','),
        isDeleted: false,
      });
    }
    if (secondaryList && secondaryList.length) {
      secondaryList.forEach((ele: any) => {
        if (ele.lists && ele.lists.length) {
          const listValues = [];
          const attachments = [];
          const correctSequence = [];
          let matchId;
          ele.lists.forEach((list: any, index: number) => {
            if (list.value) {
              listValues.push(`${index + 1}=>${list.value.replace(/ /g, '_').replace(/,/g, ' ')}`);
            }
            if (list.attachment) {
              attachments.push(`${index + 1}=>${list.attachment}`);
            }
            correctSequence.push(`${index + 1}=>${list.correctSequence}`);
            matchId = list.matchId;
          });
          matchResult.push({
            matchId: matchId || 0,
            listOrderId: 2,
            isPrimaryList: false,
            listValues: listValues.join(','),
            listAttachments: attachments.join(','),
            correctSequence: correctSequence.join(','),
            isDeleted: false,
          });
        }
      });
    }
    return matchResult;
  }

  capitalizeFirstLetter(str) {
    if (str.length === 0) {
      return str;
    }

    return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }

  formatAndSetDateTime(localDate, localTime) {
    const dateParts = localDate.split('/');
    const timeParts = localTime.split(/[:\s]/); // Split by colon and space
    const period = localTime.split(' ')[1]; // Get AM or PM
    
    let hours = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    const seconds = timeParts[2];
  
    // Convert 12-hour format to 24-hour format
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Ensure date parts are padded correctly
    const year = dateParts[2];
    const month = dateParts[1].padStart(2, '0');
    const day = dateParts[0].padStart(2, '0');
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.padStart(2, '0');
    const paddedSeconds = seconds.padStart(2, '0');
  
    // Construct ISO 8601 format string
    const isoString = `${year}-${month}-${day}T${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  
    // Create Date object
    const combinedDate = new Date(isoString);
  
    // Format the combinedDate as yyyy-MM-ddThh:mm
    return combinedDate?.toISOString().slice(0, 16);
  }

  convertToOriginalFormat(json: any): any {
    return {
      mode: json["Assessment Mode"] || '',
      count: json["Number of Attempts"] || 0,
      access: json["Access Type"] || '',
      languages: json["Supported Languages"] || [],
      singleTimer: json["Single Timer"] || false,
      jumpSections: json["Allow Jumping Between Sections"] || false,
      stakeholders: json["Stakeholders Involved"] || [],
      jumpQuestions: json["Allow Jumping Between Questions"] || false,
      assessmentLogs: json["Enable Assessment Logs"] || false,
      remoteBlocking: json["Enable Remote Blocking"] || false,
      videoStreaming: json["Enable Video Streaming"] || false,
      enableRecording: json["Enable Recording"] || false,
      enableTutorials: json["Enable Tutorials"] || false,
      screenRecording: json["Enable Screen Recording"] || false,
      enableGeoFencing: json["Enable GeoFencing"] || false,
      availableOnMobile: json["Available on Mobile"] || false,
      enableChatSupport: json["Enable Chat Support"] || false,
      enableSpecialKeys: json["Enable Special Keys"] || false,
      partialSubmission: json["Enable Partial Submission"] || false,
      IDCardVerification: json["Require ID Card Verification"] || false,
      accessNotification: json["Access Notifications"] || [],
      additionalEvidence: json["Require Additional Evidence"] || false,
      displayOfQuestions: json["Display of Questions"] || '',
      enableAppSwitching: json["Allow App Switching"] || false,
      languagesSpecifier: json["Available Languages"] || '',
      maxViolationsCount: json["Max Violation Count"] || '',
      additionalEvidences: json["Additional Evidence Required"] || [],
      enableFaceDetection: json["Enable Face Detection"] || false,
      enableStateTracking: json["Enable State Tracking"] || false,
      showDifficultyLevel: json["Show Difficulty Level"] || false,
      showMarkPerQuestion: json["Show Marks per Question"] || false,
      enableMultipleLogins: json["Allow Multiple Logins"] || false,
      enableFaceRecognition: json["Enable Face Recognition"] || false,
      enableObjectDetection: json["Enable Object Detection"] || false,
      generateResponseSheet: json["Generate Response Sheet"] || false,
      showFeedbackQuestions: json["Show Feedback Questions"] || false,
      stakeholderCategories: json["Stakeholder Categories"] || '',
      captureCandidatesImage: json["Capture Candidate's Image"] || false,
      enableHeadPoseTracking: json["Enable Head Pose Tracking"] || false,
      generateAttendanceSheet: json["Generate Attendance Sheet"] || false,
      generateFlagsInRealTime: json["Generate Real-Time Flags"] || false,
      showResultsToCandidates: json["Show Results to Candidates"] || false,
      candidatesVideoRecording: json["Enable Candidates' Video Recording"] || false,
      enableRemoteInvigilation: json["Enable Remote Invigilation"] || false,
      enableBrowserTabSwitching: json["Enable Browser Tab Switching"] || false,
      enablePracticeAssessments: json["Enable Practice Assessments"] || false,
      showResponsesToCandidates: json["Show Responses to Candidates"] || false,
      asynchronousIntegrityCheck: json["Enable Asynchronous Integrity Check"] || false,
      notifyCandidatesForResults: json["Notify Candidates About Results"] || false,
      enableChatAmongStakeholders: json["Enable Chat Among Stakeholders"] || false,
      generateHallTicketAdmitCard: json["Generate Hall Ticket/Admit Card"] || false,
      generateWarningsForCandidates: json["Generate Warnings for Candidates"] || false,
      captureImageAtRegularIntervals: json["Capture Images at Regular Intervals"] || false,
      forcedScreenSharingAndRecording: json["Force Screen Sharing and Recording"] || false,
      captureImageAtRegularIntervalsInterval: json["Capture Interval for Images"] || ''
    };
  }
  
}
