export const TOKEN_NAME = 'JwtToken';
export const SYSTEM_ACCESS = 'sys_access';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const LAYOUT = 'layout';
export const GROUPING = 'grouping';
export const THEORY = 'theory';
export const VIVA = 'viva';
export const PRACTICAL = 'practical';
export const MODULE = 'module';
export const DIFFICULTY = 'difficulty';
export const MARKS = 'marks';
export const COURSE = 'Course';
export const SCHEME = 'Scheme';
export const PROJECT = 'Project';
export const CUSTOMER = 'Customer';
export const WARM = 'Warm';
export const COLD = 'Cold';
export const LEAD = 'lead';
export const TAGS = 'tags';
export const ATTRIBUTES = 'attributes';
export const CLOSED = 'Closed';
export const EDIT = 'edit';
export const CLOSE = 'close';
export const DROP = 'drop';
export const UPDATE = 'update';
export const OPEN = 'Open';
export const DROPPED = 'Dropped';
export const DISABLED = 'Disabled';
export const DEALS = 'Deals';
export const OTHERS = 'Others';
export const OPPORTUNITIES = 'Opportunities';
export const ASSESSMENTS = 'Assessments';
export const QUESTION_PAPER_VERSIONS = 'Question Paper Versions';
export const LOGO_ASPECT_RATIO_VALIDATION =
  'The image aspect ratio must be greater than 1.5. Please try uploading a different image. (Ex. Width: 3000, Height: 1500)';
export const LOGO_SIZE_LIMIT = 5000000;
export const LOGO_FILE_SIZE_VALIDATION =
  'The image size cannot be more than 2mb.';
export const USERS = 'users';
export const MATCH_THE_FOLLOWING = 'Matching Questions';
export const MULTIPLE_CHOICE = 'Multiple Choice Question';
export const TRUE_OR_FALSE = 'True/False Question';
export const MULTI_SELECT = 'Multi-select Question';
export const FILL_IN_THE_BLANKS = 'Fill In The Blanks Question';
export const SUBJECTIVE = 'Subjective Answer Question';
export const ACTIVITY = 'Activity';
export const SCENARIO_BASED_QUESTION = 'Scenario Based Question';
export const DELETE_CONFIRMATION =
  'This action will delete the selected entry and will remove the entry from the list screen. Are you sure, you want to proceed with this action?';
export const DEFAULT_LANGUAGE_CODE = 'En';
export const INSUFFICIENT_QUESTIONS_ERROR =
  'You have insufficient questions in the category - $1 to generate the question paper. Please try again by modifying the criteria or by adding more questions.';
export const PERCENTAGE_TOTAL_HELP_TEXT =
  'The sum of percentages for Easy, Medium, Difficult should not exceed 100%.';
export const CATEGORY_COUNT_REQUIRED =
  'Please add count of questions for the category - ${obj.category}';
export const NO_PREVIEW = 'Question too big to display';
export const NO_INSTRUCTIONS = 'Instructions too big to display';
export const NO_DESCRIPTION = 'Description too big to display';
export const TABLE_COLUMN_CHARACTER_LIMIT = 400;
export const WEB = 'WEB';
export const PUSH_TOPIC = 'NavritiPushNotifications';
export const QUESTION_CATEGORY_LIST: any = [
  {
    category_name: 'viva',
  },
  {
    category_name: 'theory',
  },
  {
    category_name: 'practical',
  },
];

export const DIFFICULTY_LEVEL_LIST = [
  { level: 'Easy' },
  { level: 'Medium' },
  { level: 'Difficult' },
];

export const GROUPING_CRITERIA_LIST = [
  { criteria: 'Module/NOS', id: 'module' },
  { criteria: 'Difficulty Level', id: 'difficulty' },
  { criteria: 'Marks', id: 'marks' },
];

export const QUESTION_SELECTION_METHOD_LIST = [
  { name: 'Manual import', id: 'manual', icon: 'input' },
  { name: 'Automated selection', id: 'automated', icon: 'autorenew' },
];

export const QUESTION_PAPER_PREVIEW_LIST = [
  { name: 'View 1 question per page', id: 'multiPage' },
  { name: 'View all questions in a page', id: 'onePage' },
];

export const student_metadata = [
  { key: 'Student Name', value: 'Jane Doe' },
  { key: 'Student Id', value: 1234453 },
  { key: 'Duration', value: '02:00:00' },
  { key: 'Test Date', value: '05/06/2023' },
];

export const questionsSample = [
  {
    question_id: 62,
    question_text: '<p>Which soil is derived from basaltic rock?</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '<p>Test addition information</p>',
    question_type_id: 1,
    question_type_name: 'Multiple Choice Question',
    difficulty_level_id: 1,
    difficulty_level_name: 'Easy',
    marks: 1,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: 'Question_202210111665488026346.png',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '<p>Test hint for question</p>',
    question_feedback: 'test additional feedback',
    question_remarks: 'test answer explanation',
    question_category: 'theory',
    module: 'mod-a',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
    options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
  },
  {
    question_id: 64,
    question_text: '<p>Fill in the blanks</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '',
    question_type_id: 3,
    question_type_name: 'Fill In The Blanks Question',
    difficulty_level_id: 2,
    difficulty_level_name: 'Medium',
    marks: 2,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: '',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '',
    question_feedback: '',
    question_remarks: '',
    question_category: 'theory',
    module: 'mod-a',
    language_count: 0,
    fill_up_id: 9,
    actual_statement_text:
      '<p>Roses are [red] and voilets are [blue]. Please fill.</p>',
    blank_indices_start_end_data: [
      {
        start_index: 13,
        end_index: 17,
        blank_text: '[red]',
      },
      {
        start_index: 35,
        end_index: 40,
        blank_text: '[blue]',
      },
    ],
    count_of_blanks: 2,
    blank_text_ans_key_data: {
      '1': 'black<br>yellow',
      '2': 'black<br>grey',
    },
  },
  {
    question_id: 67,
    question_text: '<p>Subjective answers questions</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '',
    question_type_id: 5,
    question_type_name: 'Subjective Answer Question',
    difficulty_level_id: 2,
    difficulty_level_name: 'Medium',
    marks: 20,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: '',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: 22,
    sample_answer_for_reference: '<p>Test sample answer</p>',
    answer_keywords: 'test,subjective',
    evaluation_remarks: 'Test evaluation remarks',
    initial_text: 'Test initial text',
    question_hint: '',
    question_feedback: '',
    question_remarks: '',
    question_category: 'theory',
    module: 'mod-b',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
  },
  {
    question_id: 83,
    question_text: '<p>Match the following test question:</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '',
    question_type_id: 4,
    question_type_name: 'Match The Following Question',
    difficulty_level_id: 2,
    marks: 3,
    difficulty_level_name: 'Medium',
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: '',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '',
    question_feedback: '',
    question_remarks: '',
    question_category: 'theory',
    module: 'mod-a',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
    primary_list: ['Danke', 'Bitte', 'Hund'],
    secondary_list: ['Dog', 'Thank you', 'Please'],
  },
  {
    question_id: 63,
    question_text: '<p>The atomic number for lithium is 17.</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '<p>Test addition information</p>',
    question_type_id: 2,
    question_type_name: 'True/False Question',
    difficulty_level_id: 1,
    difficulty_level_name: 'Easy',
    marks: 1,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: 'Question_202210111665488026346.png',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '<p>Test hint for question</p>',
    question_feedback: 'test additional feedback',
    question_remarks: 'test answer explanation',
    question_category: 'theory',
    module: 'mod-b',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
  },
  {
    question_id: 68,
    question_text: '<p>Choose all examples of types of carbohydrates.</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '<p>Test addition information</p>',
    question_type_id: 6,
    question_type_name: 'Multiple Choice Question',
    difficulty_level_id: 1,
    difficulty_level_name: 'Easy',
    marks: 3,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: 'Question_202210111665488026346.png',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '<p>Test hint for question</p>',
    question_feedback: 'test additional feedback',
    question_remarks: 'test answer explanation',
    question_category: 'theory',
    module: 'mod-b',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
    options: [
      'Option 1',
      'Option 2',
      'Option 3',
      'Option 4',
      'Option 5',
      'Option 6',
      'Option 7',
    ],
    no_of_answers: 3,
  },
  {
    question_id: 69,
    question_text: '<p>Choose all examples of types of carbohydrates.</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '<p>Test addition information</p>',
    question_type_id: 6,
    question_type_name: 'Multiple Choice Question',
    difficulty_level_id: 1,
    difficulty_level_name: 'Easy',
    marks: 3,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: 'Question_202210111665488026346.png',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '<p>Test hint for question</p>',
    question_feedback: 'test additional feedback',
    question_remarks: 'test answer explanation',
    question_category: 'theory',
    module: 'mod-b',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
    options: [
      'Option 1',
      'Option 2',
      'Option 3',
      'Option 4',
      'Option 5',
      'Option 6',
      'Option 7',
    ],
    no_of_answers: 3,
  },
  {
    question_id: 70,
    question_text: '<p>What is aptitude, logical test? </p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '<p>Test addition information</p>',
    question_type_id: 7,
    question_type_name: 'Activity - Performance Task',
    difficulty_level_id: 1,
    difficulty_level_name: 'Easy',
    marks: 20,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: 'Question_202210111665488026346.png',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '<p>Test hint for question</p>',
    question_feedback: 'test additional feedback',
    question_remarks: 'test answer explanation',
    question_category: 'viva',
    module: 'mod-b',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
  },
  {
    question_id: 71,
    question_text: '<p>Give examples of team work.</p>',
    question_bank_id: 7,
    question_bank_title: 'TITILE 1 UPDATED',
    additional_information: '<p>Test addition information</p>',
    question_type_id: 9,
    question_type_name: 'Activity - Real Time Video',
    difficulty_level_id: 3,
    difficulty_level_name: 'Difficult',
    marks: 20,
    language_id: 1,
    language_name: 'English',
    default_language_question_id: -1,
    attachments: 'Question_202210111665488026346.png',
    is_archived: false,
    tags: '',
    course_group_label: 'Sector',
    course_subgroup_label: 'Sub-Sector',
    course_version_id: 48,
    subjective_answer_id: null,
    sample_answer_for_reference: null,
    answer_keywords: null,
    evaluation_remarks: null,
    initial_text: null,
    question_hint: '<p>Test hint for question</p>',
    question_feedback: 'test additional feedback',
    question_remarks: 'test answer explanation',
    question_category: 'viva',
    module: 'mod-a',
    language_count: 0,
    fill_up_id: null,
    actual_statement_text: null,
    blank_indices_start_end_data: null,
    count_of_blanks: null,
    blank_text_ans_key_data: null,
  },
];

export const QUESTION_TYPES = {
  1: 'multipleChoice',
  2: 'trueOrFalse',
  3: 'fillInTheBlanks',
  4: 'matchTheFollowing',
  5: 'subjective',
  6: 'multiSelect',
};

export const LANGUAGES = [
  { id: 'eng', name: 'English' },
  { id: 'hin', name: 'Hindi' },
  { id: 'kan', name: 'Kannada' },
  { id: 'tam', name: 'Tamil' },
  { id: 'tel', name: 'Telugu' },
  { id: 'mal', name: 'Malayalam' },
];

export const NOTIFICATIONS_LIST = [
  {
    createdAt: '2023-05-25T08:48:00.000Z',
    notifier: 'Admin',
    header: 'New Organization Created',
    text: 'TestOrg has just been created! Log in to your account at testorg.in to start using the platform.',
    url: '',
    read: false,
  },
  {
    createdAt: '2023-05-24T14:48:00.000Z',
    notifier: 'Admin',
    header: 'Opportunity Created',
    text: 'A new opportunity has been created for you. Please login to your account to view and manage the opportunity.',
    url: '/Sales/Opportunities',
    read: false,
  },
  {
    createdAt: '2023-05-13T12:48:00.000Z',
    notifier: 'Admin',
    header: 'New Lead Created',
    text: "Congratulations! You've just generated a new lead. Contact them ASAP to seal the deal.",
    url: '/Sales/Leads',
    read: true,
  },
  {
    createdAt: '2023-05-22T12:48:00.000Z',
    notifier: 'Admin',
    header: 'Opportunity Created',
    text: 'A new opportunity has been created for you. Please login in your account to view and manage the opportunity.',
    url: '/Sales/Opportunities',
    read: true,
  },
  {
    createdAt: '2023-05-22T12:48:00.000Z',
    notifier: 'Admin',
    header: 'New Lead Created',
    text: "Congratulations! You've just generated a new lead. Contact them ASAP to seal the deal.",
    url: '/Sales/Leads',
    read: true,
  },
  {
    createdAt: '2023-05-22T12:48:00.000Z',
    notifier: 'Admin',
    header: 'New Organization Created',
    text: 'TestOrg has just been created! Log in to your account at testorg.in to start using the platform.',
    url: '',
    read: true,
  },
  {
    createdAt: '2023-05-01T12:48:00.000Z',
    notifier: 'Sys-Admin',
    header: 'Opportunity Created',
    text: 'A new opportunity has been created for you. Please login in your account to view and manage the opportunity.',
    url: '/Sales/Opportunities',
    read: true,
  },
];

export const listOfPivotsHaveAccessToAdditionalPivotsBasedOnServices = {
  Assessment: [
    'Per Candidate',
    'Per Instance',
    'Per Course version',
    'Per Scheme',
    'Per Project',
    'Mode of delivery(Online/Offline/Hybrid)',
  ],

  Certification: [
    'Per Candidate',
    'Per Instance',
    'Per Course version',
    'Per Scheme',
    'Per Project',
    'Mode of delivery(Online/Offline/Hybrid)',
  ],

  Training: [
    'Per Candidate',
    'Per Training',
    'Per Course version',
    'Per Scheme',
    'Per Project',
    'Mode of delivery(Online/Offline/Hybrid)',
  ],

  Counselling: [
    'Per Candidate',
    'Per Instance',
    'Per Course version',
    'Per Scheme',
    'Per Project',
    'Mode of delivery(Online/Offline/Hybrid)',
  ],

  Platform: [
    'Per Candidate',
    'Per Instance',
    'Per Course version',
    'Per Scheme',
    'Per Project',
    'Mode of delivery(Online/Offline/Hybrid)',
  ],
};

export const ASSESSMENT = 'Assessment';
export const CERTIFICATION = 'Certification';
export const TRAINING = 'Training';
export const COUNSELLING = 'Counselling';
export const PLATFORM = 'Platform';
export const ADDITIONAL_PIVOT = 'additionalPivot';

export const servicesWithPivots = [
  'Assessment',
  'Certification',
  'Training',
  'Counselling',
  'Platform',
];

export const servicesWithoutPivots = [
  'Manpower',
  'Content',
  'Data entry',
  'Reimbursement',
];

export const listOfPivots = [
  { id: 0, title: 'Per Candidate' },
  { id: 1, title: 'Per Instance' },
  { id: 2, title: 'Per Course version' },
  { id: 3, title: 'Per Scheme' },
  { id: 4, title: 'Per Project' },
  { id: 5, title: 'Mode of delivery(Online/Offline/Hybrid)' },
  { id: 6, title: 'Flat rate' },
  { id: 7, title: 'Per Training' },
];

export const listOfAdditionalPivots = [
  { id: 0, title: 'Planned' },
  { id: 1, title: 'Present' },
];

export const listOfPivotsBasedOnSelectedService = {
  Assessment: [0, 1, 2, 3, 4, 5, 6],
  Certification: [0, 1, 2, 3, 4, 5, 6],
  Training: [0, 7, 2, 3, 4, 5, 6],
  Counselling: [0, 3, 4, 5, 6],
  Platform: [0, 1, 6],
};

export const listOfServices = [
  { id: 0, title: 'Assessment' },
  { id: 1, title: 'Certification' },
  { id: 2, title: 'Training' },
  { id: 3, title: 'Counselling' },
  { id: 4, title: 'Platform' },
  { id: 5, title: 'Manpower' },
  { id: 6, title: 'Content' },
  { id: 7, title: 'Data entry' },
  { id: 8, title: 'Reimbursement' },
  { id: 9, title: 'Others' },
];

export const listOfOtherServices = [{ id: 9, title: 'Not covered' }];

export const listOfNames = [
  { id: 0, title: 'Mrinal' },
  { id: 1, title: 'Dinesh' },
  { id: 2, title: 'Prabhash' },
  { id: 3, title: 'Babu' },
  { id: 4, title: 'NagaJyothi' },
  { id: 5, title: 'Vinod' },
  { id: 6, title: 'Vijay' },
];

export const listOfFlatRateTypes = [
  { id: 1, title: 'Per Candidate' },
  { id: 2, title: 'Per Instance' },
];

export const listOfDurationTypes = [
  { id: 0, title: 'Monthly' },
  { id: 1, title: 'Quarterly' },
  { id: 2, title: 'Yearly' },
];

export const listOfFeeTypeForServicesWithoutPivots = [
  { id: 0, title: 'One-Time Fee' },
  { id: 1, title: 'Recurring Fee' },
];

export interface RateMasterElement {
  s_no: number;
  customer: string;
  serviceType: string;
  uom: string;
  unitRate: string;
  effectiveFrom: string;
  effectiveTo: string;
  is_archived: string;
}

export interface RateMasterOriginalElement {
  s_no: number;
  customer: string;
  serviceType: string;
  uom: string;
  additionalPivot: string;
  unitRate: string;
  effectiveFrom: string;
  effectiveTo: string;
  is_archived: string;
}

const ORIGINAL_DATA: RateMasterOriginalElement[] = [
  {
    s_no: 1,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '05-Jun-2023',
    effectiveTo: '06-Jun-2023',
    is_archived: 'false',
  },
  {
    s_no: 2,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '01-Jun-2023',
    effectiveTo: '05-Jun-2023',
    is_archived: 'false',
  },
  {
    s_no: 3,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '12-Jun-2023',
    effectiveTo: '14-Jun-2023',
    is_archived: 'false',
  },
  {
    s_no: 4,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '16-Jun-2023',
    effectiveTo: '18-Jun-2023',
    is_archived: 'false',
  },
  {
    s_no: 5,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },

  {
    s_no: 6,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },
  {
    s_no: 7,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },
  {
    s_no: 8,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },
  {
    s_no: 9,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },

  {
    s_no: 10,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },
  {
    s_no: 11,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },
  {
    s_no: 12,
    customer: 'Amity',
    serviceType: 'Assessment',
    uom: 'avasd',
    additionalPivot: 'Per Candidate',
    unitRate: '4200',
    effectiveFrom: '14-Jul-2023',
    effectiveTo: '14-Jul-2023',
    is_archived: 'false',
  },
];

export const RATE_MASTER_ELEMENT_DATA: RateMasterElement[] = ORIGINAL_DATA.map(
  (item) => ({
    s_no: item.s_no,
    customer: item.customer,
    serviceType: item.serviceType,
    uom: `${item.uom}/${item.additionalPivot}`,
    unitRate: item.unitRate,
    effectiveFrom: item.effectiveFrom,
    effectiveTo: item.effectiveTo,
    is_archived: item.is_archived,
  })
);

export const EVALUATION_TAG_TYPES = ['Customer', 'Scheme', 'Project', 'Custom'];

export const customers = ['Customer 1', 'Customer 2'];
export const schemes = ['Scheme 1', 'Scheme 2'];
export const courses = ['Course 1', 'Course 2'];
export const projects = ['Project 1', 'Project 2'];
export const modules = ['Module 1', 'Module 2', 'Module 3'];

export const EVALUATION_PARAMETERS = [
  'Overall',
  'Question Category',
  'Grouping Criteria',
];

export const PREFERENCES = ['email', 'sms', 'push', 'in_app_push'];

export const recentSearches = [
  { item: 'John Doe', module: 'Candidates' },
  { item: 'test', module: '' },
  { item: 'Angular', module: 'Courses' },
  { item: 'Jane', module: '' },
  { item: 'Rename', module: 'Questions' },
  { item: 'John Doe', module: 'Candidates' },
  { item: 'test', module: '' },
];

export const goToAll = [
  { component: 'Candidates', route: '/Operations/Candidate' },
  { component: 'Leads', route: '/Sales/Leads' },
  { component: 'Opportunities', route: '/Sales/Opportunities' },
];

export const searchResults = [
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
];

export const searchResultsAll = [
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
  { item: 'Test User 1', module: 'Candidates' },
  { item: 'Test Lead 1', module: 'Leads' },
  { item: 'Test 1', module: 'Question Bank' },
  { item: 'test11@gmail.com', module: '' },
  { item: 'test 21', module: 'Leads' },
  { item: 'Test User2', module: 'Candidates' },
  { item: 'Test Lead2', module: 'Leads' },
  { item: 'Test 2', module: 'Courses' },
  { item: 'test12@gmail.com', module: '' },
  { item: 'test 22', module: 'Leads' },
  { item: 'Test User3', module: 'Candidates' },
  { item: 'Test Lead3', module: 'Leads' },
  { item: 'Test3', module: 'Courses' },
  { item: 'test13@gmail.com', module: '' },
  { item: 'test 23', module: 'Leads' },
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
  { item: 'Test User', module: 'Candidates' },
  { item: 'Test Lead', module: 'Leads' },
  { item: 'Test', module: 'Courses' },
  { item: 'test1@gmail.com', module: '' },
  { item: 'test 2', module: 'Leads' },
];

export const FOLDER_TYPES = {
  MOU: 'mou',
  LOGO: 'logo',
  BILL: 'bill',
  RECEIPT: 'receipt',
  INVOICE: 'invoice',
  BLUEPRINT: 'blueprint',
  QUESTION_ATTACHMENTS: 'question_attachments',
  ASSESSMENT_PLAN: 'assessment_plan',
  USER_ATTACHMENTS: 'user_attachments',
};

export const CATEGORY_LIST = [
  { id: 'theory', name: 'theory' },
  { id: 'practical', name: 'practical' },
  { id: 'viva', name: 'viva' },
];

export const RUBRIC_LEGENDS = [
  { value: 'legend-0', viewValue: 'Bad , Good , Excellent' },
  { value: 'legend-1', viewValue: 'Bad, Good , Average , Excellent' },
  { value: 'legend-2', viewValue: 'Bad , Good' },
];

export const UNLISTED_KEYS = [
  'spoc_ids',
  'business_vertical_id',
  'created_user_id',
  'geography_id',
  'industry_vertical_id',
  'modified_user_id',
  'organization_id',
  'pincode_id',
  'type_id',
  'product_id',
  'status_id',
  'item_ids',
  'modified_datetime',
];

export const extractKeys = {
  'Fill In The Blanks Question': [
    'id',
    'blank_indices_start_end_data',
    'blank_text_ans_key_data',
  ],
  'True/False Question': ['id', 'is_correct_option'],
  'Matching Questions': ['id', 'list_order_id', 'list_correct_sequence'],
  'Multiple Choice Question': ['id', 'is_correct_option'],
  'Subjective Answer Question': [
    'id',
    'answer_keywords',
    'evaluation_remarks',
    'sample_answer_for_reference',
  ],
  'Multi-select Question': ['id', 'is_correct_option'],
};
4;

export const HEADER_X_REFFERER = 'x-referer';
export const GLOBAL = 'global';
export const CREATE = 'Create';
export const VIEW = 'View';
export const DELETE = 'Delete';
export const DOWNLOAD = 'Download';
export const BULK_UPLOAD = 'Bulk Upload';
export const OPERATION_UPDATE = 'Update';

export const MENU_ACCESS = 'menu_access';

export const tableMappings: {
  [key: string]: { url: string; displayName: string };
} = {
  project: { url: '/Operations/Project', displayName: 'Projects' },
  users: { url: '/Resources/Users', displayName: 'Users' },
  questions: { url: '/Content/QuestionBank', displayName: 'Questions' },
  course_master: { url: '/Content/Courses', displayName: 'Courses' },
  lead_master: { url: '/Sales/Leads', displayName: 'Leads' },
  customer_master: { url: '/Sales/Customer', displayName: 'Customers' },
  opportunity_master: { url: '/Sales/Leads', displayName: 'Opportunities' },
  scheme_master: { url: '/Sales/Scheme', displayName: 'Schemes' },
  candidates: { url: '/Operations/Candidate', displayName: 'Candidates' },
  assessments: {
    url: '/Operations/AssessmentPlan',
    displayName: 'Assessments',
  },
};

export const barChartOptions = {
  chart: {
    type: 'bar',
    height: 250,
    marginTop: 20,
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
    crosshair: false,
  },
  yAxis: {
    min: 0,
    title: {
      text: null,
    },
    gridLineWidth: 0,
    lineWidth: 0,
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    bar: {
      pointPadding: 0.1,
      groupPadding: 0.1,
      borderWidth: 0,
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'top',
    x: 0,
    y: -10,
  },
  series: [],
};

export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_YYYY_MM_DD_HH_MM_SS = 'YYYY-MM-DD HH:mm:ss';

export const STATUS_LIST = [
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'In Active' },
];

export const SAMPLE_RULES = {
  "Assessment Mode": {
    "label": "Mode",
    "value": "",
    "description": "Choose the mode of the assessment",
    "allowedValues": ["Online", "Offline", "Mixed"]
  },
  "Number of Attempts": {
    "label": "Number of Attempts",
    "value": 2,
    "description": "How many times the user can attempt the assessment",
    "allowedValues": "Any positive integer"
  },
  "Access Type": {
    "label": "Access",
    "value": "",
    "description": "Specify access control",
    "allowedValues": ["Single URL", "Unique URL for each candidate"]
  },
  "Supported Languages": {
    "label": "Supported Languages",
    "value": [],
    "description": "List of supported languages",
    "allowedValues": ["English", "Spanish", "French", "Others"]
  },
  "Single Timer": {
    "label": "Single timer",
    "value": false,
    "description": "Enable or disable a single timer for the entire assessment",
    "allowedValues": [true, false]
  },
  "Allow Jumping Between Sections": {
    "label": "Jump sections",
    "value": true,
    "description": "Can the user jump between sections?",
    "allowedValues": [true, false]
  },
  "Stakeholders Involved": {
    "label": "Stakeholders Involved",
    "value": [71],
    "description": "List of stakeholder IDs",
    "allowedValues": "Any array of integers"
  },
  "Allow Jumping Between Questions": {
    "label": "Jump questions",
    "value": true,
    "description": "Can users jump between questions?",
    "allowedValues": [true, false]
  },
  "Enable Assessment Logs": {
    "label": "Assessment Logs",
    "value": true,
    "description": "Enable detailed logs of the assessment?",
    "allowedValues": [true, false]
  },
  "Enable Remote Blocking": {
    "label": "Enable remote invigilation",
    "value": true,
    "description": "Allow remote blocking functionality",
    "allowedValues": [true, false]
  },
  "Enable Video Streaming": {
    "label": "Candidate's video recording",
    "value": false,
    "description": "Enable video streaming during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable Recording": {
    "label": "Enable recording",
    "value": false,
    "description": "Enable recording during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable Tutorials": {
    "label": "Enable tutorials",
    "value": true,
    "description": "Enable tutorial guides for users?",
    "allowedValues": [true, false]
  },
  "Enable Screen Recording": {
    "label": "Screen recording",
    "value": false,
    "description": "Enable screen recording during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable GeoFencing": {
    "label": "Enable geo-fencing",
    "value": false,
    "description": "Enable geofencing to restrict access based on location?",
    "allowedValues": [true, false]
  },
  "Available on Mobile": {
    "label": "Available on mobile",
    "value": false,
    "description": "Make the assessment available on mobile devices?",
    "allowedValues": [true, false]
  },
  "Enable Chat Support": {
    "label": "Enable chat support",
    "value": false,
    "description": "Enable chat support during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable Special Keys": {
    "label": "Enable special keys",
    "value": false,
    "description": "Allow the use of special keys (copy, paste, etc.)?",
    "allowedValues": [true, false]
  },
  "Enable Partial Submission": {
    "label": "Partial submission",
    "value": true,
    "description": "Allow candidates to submit the assessment partially?",
    "allowedValues": [true, false]
  },
  "Require ID Card Verification": {
    "label": "ID card verification",
    "value": true,
    "description": "Require ID card verification before starting the assessment?",
    "allowedValues": [true, false]
  },
  "Access Notifications": {
    "label": "Access Notifications",
    "value": [],
    "description": "List of access notifications",
    "allowedValues": ["Email","SMS","Email and SMS"]
  },
  "Require Additional Evidence": {
    "label": "Additional evidence",
    "value": false,
    "description": "Require additional evidence for verification?",
    "allowedValues": [true, false]
  },
  "Display of Questions": {
    "label": "Display of questions",
    "value": "Both, with an option to toggle",
    "description": "How questions should be displayed (single or multiple at once)",
    "allowedValues": ["All in one screen", "One per screen", "Both, with an option to toggle"]
  },
  "Allow App Switching": {
    "label": "Enable app switching",
    "value": false,
    "description": "Allow candidates to switch apps during the assessment?",
    "allowedValues": [true, false]
  },
  "Available Languages": {
    "label": "Available Languages",
    "value": "All available",
    "description": "Specify available languages for the assessment",
    "allowedValues": ["All available", "Selected languages"]
  },
  "Max Violation Count": {
    "label": "Max violation count",
    "value": "",
    "description": "Maximum number of violations allowed before termination",
    "allowedValues": "Any positive integer"
  },
  "Additional Evidence Required": {
    "label": "Additional evidence",
    "value": [],
    "description": "List of additional evidence types required",
    "allowedValues": "Array of evidence types"
  },
  "Enable Face Detection": {
    "label": "Enable face detection",
    "value": true,
    "description": "Enable face detection during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable State Tracking": {
    "label": "Enable state tracking",
    "value": true,
    "description": "Enable tracking of the candidate's state during the assessment?",
    "allowedValues": [true, false]
  },
  "Show Difficulty Level": {
    "label": "Show difficulty level",
    "value": false,
    "description": "Show the difficulty level of each question?",
    "allowedValues": [true, false]
  },
  "Show Marks per Question": {
    "label": "Show mark per question",
    "value": false,
    "description": "Display the marks assigned to each question?",
    "allowedValues": [true, false]
  },
  "Allow Multiple Logins": {
    "label": "Enable multiple logins",
    "value": false,
    "description": "Allow candidates to log in from multiple devices?",
    "allowedValues": [true, false]
  },
  "Enable Face Recognition": {
    "label": "Enable face recognition",
    "value": true,
    "description": "Enable face recognition during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable Object Detection": {
    "label": "Enable object detection",
    "value": true,
    "description": "Enable object detection during the assessment?",
    "allowedValues": [true, false]
  },
  "Generate Response Sheet": {
    "label": "Generate response sheet",
    "value": false,
    "description": "Generate a response sheet after the assessment?",
    "allowedValues": [true, false]
  },
  "Show Feedback Questions": {
    "label": "Show feedback questions",
    "value": false,
    "description": "Show feedback questions after the assessment?",
    "allowedValues": [true, false]
  },
  "Stakeholder Categories": {
    "label": "Stakeholder Categories",
    "value": "47",
    "description": "Specify stakeholder categories involved",
    "allowedValues": "Any integer or category code"
  },
  "Capture Candidate's Image": {
    "label": "Capture candidate's image (beginning & end)",
    "value": true,
    "description": "Capture the candidate's image during the assessment?",
    "allowedValues": [true, false]
  },
  "Enable Head Pose Tracking": {
    "label": "Enable head pose tracking",
    "value": true,
    "description": "Enable tracking of the candidate's head pose?",
    "allowedValues": [true, false]
  },
  "Generate Attendance Sheet": {
    "label": "Generate attendance sheet",
    "value": true,
    "description": "Generate an attendance sheet for the assessment?",
    "allowedValues": [true, false]
  },
  "Generate Real-Time Flags": {
    "label": "Generate flags in real time",
    "value": true,
    "description": "Generate real-time flags for violations during the assessment?",
    "allowedValues": [true, false]
  },
  "Show Results to Candidates": {
    "label": "Show results to candidates",
    "value": true,
    "description": "Display the results to candidates after completion?",
    "allowedValues": [true, false]
  },
  "Enable Candidates' Video Recording": {
    "label": "Candidates' video recording",
    "value": false,
    "description": "Record candidates' videos during the assessment?",
    "allowedValues": [true, false]
  }
}

export const COURSE_MODULE_HEADING_NAME = "course_module_heading_name"