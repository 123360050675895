import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from '@app/shared/services/util.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private apiUrl = 'https://navrititechnologies.freshdesk.com/api/v2';
  navrititechnologies
  private headers = new HttpHeaders({
    Authorization: 'Basic UnZRSVdDTVFHYm5pSU5Uelh4YUQ6WA==',
    Cookie: '_x_w=7_2',
  });

  constructor(private http: HttpClient, private util: UtilService) {}

  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  getTicketData(id: any): Observable<any> {
    const url = `${this.apiUrl}/tickets/${id}?include=conversations`;
    return this.http.get(url, { headers: this.headers });
  }

  sendComment(message: string, id: any): Observable<any> {
    const body = {
      body: message,
      to_emails: ['support@certiplate.com'],
    };
    const url = `${this.apiUrl}/tickets/${id}/reply_to_forward`;

    return this.http.post(url, body, { headers: this.headers });
  }

  closeTicket(id: any): Observable<any> {
    const body = {
      status: 5,
    };
    const url = `${this.apiUrl}/tickets/${id}`;

    return this.http.put(url, body, { headers: this.headers });
  }
}
