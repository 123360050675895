<div class="modal-header text-center">
  <h3 id="hdrImageTitle" class="modal-title" style="color: black;">Upload</h3>
  <button type="button" style="color: black;" title="Close" class="close float-right" data-dismiss="modal"
    aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>
  <div class="mb-4">
    <div class="d-flex justify-content-between">
      <div style="font-size: 16px;" class="font-weight-bold">Upload Instructions</div>
      <div>
        <button *ngIf="data.type == 'assessments'" type="button" mat-raised-button class="sample-rules-download mr-2"
          (click)="downloadRulesSample()">
          Download sample rules
        </button>
        <button type="button" mat-raised-button class="sample-download" (click)="downloadSample()">Download sample
          data</button>
      </div>
    </div>
    <ul>
      <li>The maximum allowable file size is 5MB per upload.</li>
      <li><span class="font-weight-bold text-danger">Acceptable file format: </span>Microsoft Excel (XLS or XLSX)</li>
    </ul>
  </div>
  <div class="grid-container" *ngIf="data.type == 'course_master'" >
    <div class="grid-item" *ngFor="let course of data.courseFrameworkList">
      <strong>Course Framework:</strong> {{ course.name }} |
      <strong>Framework ID:</strong> {{ course.course_framework_id }}
    </div>
  </div>
  <div class="container" style="text-align: center" (fileDropped)="onFileDrop($event)">
    <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)"
      [disabled]="files.length" accept=".xls,.xlsx" />
    <mat-icon class="white-icon" matTooltip="Upload excel"
      style="transform: scale(2); margin-bottom: 10px">cloud_upload</mat-icon>
    <br />
    <h3>Drag and drop file here</h3>
    <br />
    <h4>or</h4>
    <br />
    <label for="fileDropRef" [ngStyle]="{'background-color': files?.length ? '#808080': '#22c95cfa'}">Browse for
      file</label>
  </div>
  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <div class="file-icon" style="width: 30px; font-size: 100px">
        <mat-icon> attach_file</mat-icon>
      </div>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        <div class="progress-cont">
          <div class="progress" [style.width]="file?.progress + '%'"
            [ngStyle]="{'background-color': errorList?.length ? '#ff0000': '#22c95cfa'}"></div>
        </div>
      </div>

      <!-- <div class="delete" (click)="deleteFile(i)">
        <mat-icon>delete_forever</mat-icon>
      </div> -->
    </div>
  </div>
</mat-dialog-content>
<div class="d-flex justify-content-end mr-3" *ngIf="errorList.length && fileStatus === 'Error'">
  <button type="button" (click)="downloadLogs()">Download error logs</button>
</div>
<div class="logger" *ngIf="files.length" #scrollLogs [scrollTop]="scrollLogs.scrollHeight">
  <div *ngIf="uploading">Uploading file {{processingFile}}...</div>
  <div *ngIf="validating">Validating...</div>
  <div *ngIf="uploaded || errorList.length">Processing time: {{counter}}s</div>
  <div *ngIf="errorList.length">
    <div class="error-list"><u>Errors:</u></div>
    <ol class="errors">
      <li *ngFor="let item of errorList">
        {{item}}
      </li>
    </ol>
  </div>
  <div *ngIf="errorList.length && uploaded">Please fix the errors and try uploading again.</div>
  <div *ngIf="!errorList.length && uploaded">File validation successful! File is ready to upload.</div>
</div>
<button type="submit" mat-raised-button class="upload-button mb-5"
  [disabled]="(uploaded && errorList.length) || (!files.length) || (files.length && processing) || fileStatus === 'Error'"
  (click)="uploadFile()">
  <mat-progress-spinner class="mat-spinner-color" *ngIf="showProgress" mode="indeterminate"
    [diameter]="30"></mat-progress-spinner>
  <span *ngIf="!showProgress">Upload file</span>
</button>
<div class="mt-5"></div>