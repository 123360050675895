import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SAMPLE_RULES, USERS } from '@app/shared/constants/application-constants';
import { URL } from '@app/shared/constants/service-urls';
import { ApiService } from '@app/shared/services/api.service';
import { BulkUpload } from '@app/shared/services/bulk.upload.service';
import { DownloadService } from '@app/shared/services/download.service';
import { UtilService } from '@app/shared/services/util.service';
import { Observable, throwError } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent {
  files: any[] = [];
  isError: boolean = false;
  uploading: boolean = false;
  validating: boolean = false;
  processingFile: string = '';
  rowCount: number = 0;
  errorList = [];
  uploaded: boolean = false;
  progress: number = 0;
  multisheets: boolean = false;
  processing: boolean = false;
  fileStatus: 'Success' | 'Error';
  showProgress: boolean;
  successMsg: string;
  serviceError: string;
  counter: number = 0;
  counterInterval: any;
  headers: any = [];
  processed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string; items: any; courseFrameworkList: any; },
    private util: UtilService,
    private api: ApiService,
    private downloadSrvc: DownloadService,
    private bulkUploadService: BulkUpload
  ) { }

  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  onFileDrop($event: any[]) {
    if (this.files.length === 0) {
      if (Number($event[0]?.size) > 5000000) {
        this.processFilesList($event);
      } else {
        this.serviceError = this.util.getIntlErrorMessage(
          'UPLOAD',
          'file-size-error'
        );
        this.util.simpleDialog({
          title: 'Error',
          message: this.serviceError,
        });
      }
    }
  }

  fileBrowseHandler(files: any[]) {
    if (Number(files[0].size) < 5000000) {
      this.processFilesList(files);
    } else {
      this.serviceError = this.util.getIntlErrorMessage(
        'UPLOAD',
        'file-size-error'
      );
      this.util.simpleDialog({
        title: 'Error',
        message: this.serviceError,
      });
    }
  }

  validateHeaders = (sheet: any, type: string, sheetIndex: any) => {
    let missingHeaders = [];
    const requiredFields = this.util.getFieldList(type);
    console.log(requiredFields)
    let headers = new Set();

    requiredFields?.forEach((item, index) => {
      const cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })];
      if (cell && cell.t) {
        const header = XLSX.utils.format_cell(cell);
        headers.add(header);
        if (!requiredFields.some((field) => field.field === header)) {
          missingHeaders.push(header);
        }
      }
    });
    this.progress = 10;
    if (this.headers.length !== new Set([...this.headers])?.size) {
      const errorMsg = this.util.getIntlErrorMessage('UPLOAD', 'header-repeat');

      this.errorList.push(errorMsg);
    }
    if (missingHeaders.length !== 0) {
      const errorMsg = this.util.getIntlErrorMessage(
        'UPLOAD',
        'header-mismatch'
      );

      this.errorList.push(
        this.multisheets
          ? errorMsg
            .replace(/^/, `Sheet ${sheetIndex} : `)
            .replace('$1', missingHeaders?.join(', '))
          : errorMsg.replace('$1', missingHeaders?.join(', '))
      );
    }
    setTimeout(() => {
      this.progress = 0;
    }, 10);
  };

  validateData = (
    object: any,
    type: string,
    fileIndex: string | number,
    sheetIndex: any
  ) => {
    const requiredFields = this.util.getFieldList(type);
    const jsonObj = JSON.parse(JSON.stringify(object));
    for (const item of requiredFields) {
      let isError = false;
      for (const [index, cell] of jsonObj.entries()) {
        if (
          item.required &&
          (cell[item.field] === '' || cell[item.field] == undefined)
        ) {
          const errorMsg = this.util.getIntlErrorMessage('UPLOAD', 'required');
          this.errorList.push(
            errorMsg
              .replace(/^/, `Sheet ${sheetIndex} : `)
              .replace('$1', index + 1)
              .replace('$2', item.field)
          );
          isError = true;
        }
        if (isError) continue;
        if (item.datatype) {
          if (
            item.required &&
            (cell[item.field] === '' || cell[item.field] == undefined)
          ) {
            if (item.datatype === 'array') {
              if (
                cell[item.field]?.length > 1 &&
                !Array.isArray(cell[item.field]?.split(','))
              ) {
                isError = true;
              }
            } else if (item.datatype === 'json') {
              const checkIfJson = this.util.isJson(String(cell[item.field]));
              if (!checkIfJson) isError = true;
            } else {
              if (typeof cell[item.field] !== item.datatype) {
                isError = true;
              }
            }

            if (isError) {
              const errorMsg = this.util.getIntlErrorMessage(
                'UPLOAD',
                'datatype-mismatch'
              );
              this.errorList.push(
                errorMsg
                  .replace(/^/, `Sheet ${sheetIndex} : `)
                  .replace('$1', index + 1)
                  .replace('$2', cell[item.field])
                  .replace('$3', item.dataDescription)
                  .replace('$4', item.field)
              );
            }
          }
        }
        if (isError) continue;
        if (item.regex && !item.regex.test(cell[item.field])) {
          const errorMsg = this.util.getIntlErrorMessage(
            'UPLOAD',
            'pattern-mismatch'
          );
          this.errorList.push(
            errorMsg
              .replace(/^/, `Sheet ${sheetIndex} : `)
              .replace('$1', index + 1)
              .replace('$2', cell[item.field])
              .replace('$3', item.patternDescription)
              .replace('$4', item.field)
          );
        }
        if (this.files[fileIndex]?.progress + 80 / this.rowCount > 0)
          this.progress = 100 - this.files[fileIndex]?.progress;
        else this.progress = 80 / this.rowCount;
      }
    }
  };

  startTimer() {
    this.counterInterval = setInterval(() => {
      this.counter++;
    }, 1000);
  }

  parseExcel(file: Blob, type: string, fileIndex: number) {
    let isError = false;
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary',
      });
      this.progress = 5;
      this.multisheets = workbook.SheetNames?.length > 1;
      workbook.SheetNames.every(
        async function (sheetName, index) {
          const XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          this.progress = 5;
          this.rowCount = XL_row_object.length;
          if (this.rowCount === 0) {
            const errorMsg = this.util.getIntlErrorMessage(
              'UPLOAD',
              'empty-records'
            );
            this.errorList.push(
              this.multisheets
                ? errorMsg.replace(/^/, `Sheet ${index + 1} : `)
                : errorMsg
            );
          }
          await this.validateHeaders(
            workbook.Sheets[sheetName],
            type,
            index + 1
          );

          setTimeout(() => {
            this.validateData(XL_row_object, type, fileIndex, index + 1);
          }, 1000);

          this.processed = true;
          return true;
        }.bind(this),
        this
      );
    };

    reader.onerror = function (ex) {
      return throwError(
        'There was an error while trying to read the file. Please try again later.'
      );
    };
    reader.readAsBinaryString(file);

    return isError;
  }

  async uploadFilesSimulator(index: number) {
    setTimeout(() => {
      this.uploading = true;
      setTimeout(() => {
        this.validating = true;
        if (index === this.files.length) {
          return;
        } else {
          this.parseExcel(this.files[0], this.data.type, index);
          const progressInterval = setInterval(() => {
            if (this.files[index].progress >= 100 || this.processed) {
              this.files[index].progress = 100;
              clearInterval(progressInterval);
              clearInterval(this.counterInterval);
              this.uploaded = true;
              this.processing = false;
              this.fileStatus =
                this.errorList?.length > 0 ? 'Error' : 'Success';
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += this.progress;
            }
          }, 5);
        }
      }, 1000);
    }, 1000);
  }

  extractPincodes(file: File): Observable<number[]> {
    const pincodes: number[] = [];

    return new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          if (jsonData && jsonData.length > 0) {
            jsonData.forEach((row: any) => {
              const pincode = row['pincode_id'];
              if (pincode) {
                pincodes.push(parseInt(pincode, 10));
              }
            });
            observer.next(pincodes);
          } else {
            observer.error('No data found in the sheet');
          }
          observer.complete();
        } else {
          observer.error('Failed to read file');
        }
      };

      reader.onerror = (error) => {
        observer.error(error);
      };

      reader.readAsBinaryString(file);
    });
  }

  appendGeographicalIDs(
    file: File,
    geographicalIDs: { [key: number]: number }
  ): Promise<File | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target?.result;

        if (data) {
          try {
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            // Append geographical IDs
            jsonData.forEach((row: any) => {
              const pincode = row['pincode_id'];
              if (pincode && geographicalIDs[pincode] !== undefined) {
                row['geography_id'] = geographicalIDs[pincode];
              }
            });

            const updatedSheet = {
              ...sheet,
              ...XLSX.utils.json_to_sheet(jsonData),
            };
            const newWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(newWorkbook, updatedSheet, sheetName);

            const wbout = XLSX.write(newWorkbook, {
              bookType: 'xlsx',
              type: 'binary',
            });
            const blob = new Blob([this.s2ab(wbout)], {
              type: 'application/octet-stream',
            });

            const updatedFile = new File([blob], `Updated_${file.name}`, {
              type: file.type,
            });
            resolve(updatedFile);
          } catch (error) {
            console.error('Error processing the file:', error);
            reject(error);
          }
        } else {
          reject(new Error('No data in file.'));
        }
      };

      reader.onerror = (error) => {
        console.error('Error processing the file:', error);
        reject(error);
      };

      reader.readAsBinaryString(file);
    });
  }

  fetchGeographicalIDs(pincodes: number[]): Promise<{ [key: number]: number }> {
    const geographicalIDs: { [key: number]: number } = {};
    return new Promise((resolve, reject) => {
      this.bulkUploadService.getGeographicalIDs(pincodes).subscribe(
        (response: any) => {
          if (response.body && response.body.responseBody) {
            Object.keys(response.body.responseBody).forEach((key: string) => {
              const item = response.body.responseBody[key];
              geographicalIDs[item.pin_code] = item.geography_id;
            });
            resolve(geographicalIDs);
          } else {
            this.util.simpleDialog({
              title: 'error',
              message: 'Failed to fetch pincodes',
            });
          }
        },
        (error) => {
          this.util.simpleDialog({
            title: 'error',
            message: error,
          });
          this.dialogRef.close({ success: false });
        }

      );
    });
  }

  s2ab(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  processFilesList(files: Array<any>) {
    this.startTimer();
    this.errorList = [];
    this.rowCount = 0;
    this.progress = 0;
    this.uploaded = false;
    this.processing = false;
    this.multisheets = false;

    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.processing = true;
    this.processingFile = this.files[0]?.name;
    this.uploadFilesSimulator(0);
  }

  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  downloadLogs() {
    let downloadText = {
      'File name': this.files[0].name,
      'File size': this.files[0].size,
      'Processing time': `${this.counter}s`,
      'Processed headers': this.headers.join(','),
      'Error list': this.errorList.join('\n'),
    };
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(
        JSON.stringify(downloadText)
      )}`
    );
    downloadLink.setAttribute(
      'download',
      `${this.files[0].name.replace(/.xlsx/g, '')}_errors.txt`
    );
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  async uploadFile() {
    this.showProgress = true;
    const file = this.files[0];
    const processed_file = await this.processSheetData(file);
    const pincodes: number[] = await this.extractPincodes(
      processed_file
    ).toPromise();

    if (pincodes.length > 0) {
      try {
        const geographicalIDs = await this.fetchGeographicalIDs(pincodes);
        const updatedFile = await this.appendGeographicalIDs(
          processed_file,
          geographicalIDs
        );

        if (updatedFile) {
          let formData = new FormData();
          formData.append('file', updatedFile);
          await this.uploadToServer(formData);
        } else {
          throw new Error('Failed to update file.');
        }
      } catch (error) {
        this.handleError(error);
      }
    } else {
      let formData = new FormData();
      formData.append('file', processed_file);
      await this.uploadToServer(formData);
    }

    this.showProgress = false;
  }

  async uploadToServer(formData: FormData) {
    try {
      let resp;
      if (!this.data.items) {
        resp = this.api.post(
          `${URL.ADMIN.UPLOAD_FILE}?tableName=${this.data.type}&orgId=${this.orgID}&type=formdata`,
          formData
        );
      } else {
        if (this.data.type === USERS) {
          resp = this.api.post(
            `${URL.ADMIN.UPLOAD_FILE}?tableName=${this.data.type}&userRoleId=${this.data.items.roleId}&userCategoryId=${this.data.items.categoryId}&type=formdata`,
            formData
          );
        }
      }

      resp.subscribe(
        (data: any) => {
          this.successMsg = this.util.getSuccessMessage(
            'BULK_UPLOAD',
            data.body.responseHead.statusCode,
            data.body.responseHead.statusDesc
          );
          this.util.simpleDialog({
            title: 'Updated',
            message: this.successMsg,
          });
          this.files = [];
          this.dialogRef.close();
        },
        (err: any) => {
          this.handleError(err);
        }
      );
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error: any) {
    this.showProgress = false;
    this.serviceError = 'Failed to process the file. Please try again later.';
    this.util.simpleDialog({
      title: 'Error',
      message: this.serviceError,
    });
    console.error('Error processing the file:', error);
  }

  downloadSample() {
    this.downloadSrvc
      .downloadSampleFile(this.data.type)
      .subscribe((data: any) => {
        let binaryData = [];
        binaryData.push(data.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'blob' })
        );
        downloadLink.setAttribute('download', `${this.data.type}_sample.xlsx`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  downloadRulesSample() {
    const jsonString = JSON.stringify(SAMPLE_RULES, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });

    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'rules-sample.txt';
    anchor.click();

    window.URL.revokeObjectURL(url);
  }

  processSheetData(file: File): Promise<File | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target?.result;

        if (data) {
          try {
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            const tagArray = [];
            jsonData.forEach((row: any) => {
              [
                'business_vertical_id',
                'industry_vertical_id',
                'primary_spoc_id',
                'course_group_id',
                'course_subgroup_id',
                'course_id',
                'course_version_id'
              ].forEach((field) => {
                if (this.data.type == 'assessments') {
                  if (field == 'course_id') {
                    tagArray.push({
                      "id": parseInt(row[field].split('|')[0].trim()),
                      "key": "Course",
                      "type": "Course",
                      "Course": row[field].split('|')[1].trim()
                    })
                  } else if (field == 'course_version_id') {
                    tagArray.push({
                      "id": parseInt(row[field].split('|')[0].trim()),
                      "key": "Course Version ",
                      "type": "custom",
                      "Course Version ": row[field].split('|')[1].trim()
                    })
                  }
                  console.log(tagArray, "====")
                }
                if (row[field]) {
                  row[field] = parseInt(row[field].split('|')[0].trim());
                }
              });

              if (this.data.type == 'assessments') {
                console.log(tagArray)
                row['tags'] = tagArray;
              }

              // Convert `spoc_ids` to an array of integers
              if (row['spoc_ids']) {
                row['spoc_ids'] = `{${row['spoc_ids']
                  .replace(/[\[\]\"]/g, '')
                  .split(',')
                  .map((id: string) => parseInt(id.split('|')[0].trim()))
                  .join(', ')}}`;
              }
              if (row['type_id']) {
                switch (row['type_id'].toLowerCase()) {
                  case 'open':
                    row['type_id'] = 1;
                    break;
                  case 'closed':
                    row['type_id'] = 2;
                    break;
                  case 'dropped':
                    row['type_id'] = 3;
                    break;
                  default:
                    row['type_id'] = null;
                }
              }

              // Map `status_id` text values to corresponding IDs
              if (row['status_id']) {
                switch (row['status_id'].toLowerCase()) {
                  case 'warm':
                    row['status_id'] = 1;
                    break;
                  case 'cold':
                    row['status_id'] = 2;
                    break;
                  default:
                    row['status_id'] = null;
                }
              }

              if (row['rules']) {
                let obj = {}
                Object.keys(row['rules'])?.forEach((item: any) => {
                  obj[item.label] = item.value
                })

                row['rules'] = this.util.convertToOriginalFormat(obj);
              }
            });


            const updatedSheet = XLSX.utils.json_to_sheet(jsonData);
            const newWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(newWorkbook, updatedSheet, sheetName);

            const wbout = XLSX.write(newWorkbook, {
              bookType: 'xlsx',
              type: 'binary',
            });
            const blob = new Blob([this.s2ab(wbout)], {
              type: 'application/octet-stream',
            });

            const updatedFile = new File([blob], `Updated_${file.name}`, {
              type: file.type,
            });
            resolve(updatedFile);
          } catch (error) {
            console.error('Error processing the file:', error);
            reject(error);
          }
        } else {
          reject(new Error('No data in file.'));
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        reject(error);
      };

      reader.readAsBinaryString(file);
    });
  }
}

