import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { getXReferer } from '@app/operation/project/project.constants';
import {
  barChartOptions,
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_YYYY_MM_DD_HH_MM_SS,
  HEADER_X_REFFERER,
  UNLISTED_KEYS,
} from '@app/shared/constants/application-constants';
import * as _ from 'lodash';
import { DashboardService } from './dashboard.service';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { UtilService } from '@app/shared/services/util.service';
import { ExportService } from '@app/exportservice/export.service';
import { convertDate } from '@app/finance/finance-utilities';
import { element } from 'protractor';

export interface PeriodicElement {
  Vertical: string;
  Allocated: number;
  Scheduled: number;
  Assessed: number;
  Invoiced: number;
  Cancelled: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    Vertical: 'Corporate',
    Allocated: 2510,
    Scheduled: 85,
    Assessed: 421,
    Invoiced: 116,
    Cancelled: 30,
  },
  {
    Vertical: 'LabourNet',
    Allocated: 2510,
    Scheduled: 85,
    Assessed: 421,
    Invoiced: 116,
    Cancelled: 30,
  },
  // ... other data elements
];

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  isOrgSuperAdmin: boolean = false;
  isLoading = false;
  sidebarOpen = false;
  pageSizeOptions = [];
  unlistedKeys: any = UNLISTED_KEYS;

  currentDate = moment().startOf('day').format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);

  startDate = this.currentDate;
  endDate = this.currentDate;

  currentAndPreviousDates = {
    currentStartDate: this.currentDate,
    currentEndDate: moment(this.currentDate)
      .add(1, 'days')
      .startOf('day')
      .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
    previousStartDate: moment(this.currentDate)
      .subtract(1, 'days')
      .startOf('day')
      .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
    previousEndDate: moment(this.currentDate)
      .startOf('day')
      .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
  };

  selectedOrganisation: number = -1;

  salesBarchartOptions = _.cloneDeepWith(barChartOptions);
  contentBarChartOptions = _.cloneDeepWith(barChartOptions);
  operationsBarChartOptions = _.cloneDeepWith(barChartOptions);
  organizationBarChartOptions: any = _.cloneDeepWith(barChartOptions);

  dashboardElements: any = [];
  selectedDateFilter: any;
  option: string;
  currentPanel: string;
  downloadText: string = 'Download';
  downloadData = [];
  public segmentsArray = ['Today', '7d', '2w', '1m', 'Custom'];

  displayedColumns: string[] = [
    'position',
    'name',
    'weight',
    'symbol',
    'a',
    'b',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private route: Router,
    private dashboardSrvc: DashboardService,
    private cdr: ChangeDetectorRef,
    private utilService: UtilService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const x_referer = getXReferer(this.route.url);
    localStorage.setItem(HEADER_X_REFFERER, x_referer);
    this.dataSource.paginator = this.paginator;

    const superUserId = localStorage.getItem('superUserId');
    if (superUserId && parseInt(superUserId, 10) === 1) {
      this.isOrgSuperAdmin = true;
    }

    this.salesBarchartOptions.xAxis.categories = [];
    this.salesBarchartOptions.series = [
      {
        name: 'Leads',
        data: [],
        color: '#BD3630',
      },
      {
        name: 'Sales',
        data: [],
        color: '#27CA5F',
      },
    ];

    this.contentBarChartOptions.xAxis.categories = [
      'Easy',
      'Medium',
      'Difficult',
    ];
    this.contentBarChartOptions.series = [
      {
        name: 'Previous Question count',
        data: [],
        color: '#BD3630',
      },
      {
        name: 'Current questiion count',
        data: [],
        color: '#27CA5F',
      },
    ];

    this.operationsBarChartOptions.xAxis.categories = [];
    this.operationsBarChartOptions.series = [
      {
        name: 'Violation Count',
        data: [],
        color: '#27CA5F',
      },
    ];

    this.organizationBarChartOptions.xAxis.categories = [];
    this.organizationBarChartOptions.series = [
      {
        name: 'Last week',
        data: [],
        color: '#BD3630',
      },
      {
        name: 'This week',
        data: [],
        color: '#27CA5F',
      },
    ];

    this.dashboardElements = [
      {
        type: 'Activity Tracker',
        data: {},
        isPanelOpened: false,
        selectedDateFilter: 'day',
      },
      {
        type: 'Sales',
        data: {},
        isPanelOpened: false,
        barChartOptions: this.salesBarchartOptions,
        selectedDateFilter: 'day',
        selectedOrg: -1
      },
      {
        type: 'Content',
        data: {},
        isPanelOpened: false,
        barChartOptions: this.contentBarChartOptions,
        selectedDateFilter: 'day',
        selectedOrg: -1
      },
      {
        type: 'Operations',
        data: {},
        isPanelOpened: false,
        barChartOptions: this.operationsBarChartOptions,
        selectedDateFilter: 'day',
        selectedOrg: -1
      },
      {
        type: 'Finance',
        data: {},
        isPanelOpened: false,
        selectedDateFilter: 'day',
        selectedOrg: -1
      },
      {
        type: 'Resources',
        data: {},
        isPanelOpened: false,
        selectedDateFilter: 'day',
        selectedOrg: -1
      },
    ];

    if (this.isOrgSuperAdmin) {
      this.dashboardElements.push({
        type: 'Organization data by industry',
        data: {},
        isPanelOpened: false,
        barChartOptions: this.organizationBarChartOptions,
        selectedDateFilter: 'day',
      });
    }
    this.isLoading = false;
  }

  findChangedKeys(oldObj: any, newObj: any) {
    const changedKeys = [];

    if (oldObj && newObj) {
      for (const key in newObj) {
        if (newObj.hasOwnProperty(key)) {
          if (
            !oldObj.hasOwnProperty(key) ||
            !_.isEqual(oldObj[key], newObj[key])
          ) {
            changedKeys.push(key);
          }
        }
      }
    }
    return changedKeys;
  }

  getContentMapping(element: any) {
    const keyName = this.findChangedKeys(
      element?.old_values,
      element?.new_values
    );

    let constructedContent = [];

    keyName.forEach((key) => {
      if (!this.unlistedKeys.includes(key)) {
        // Add both old and new values for each key that changed
        constructedContent.push({
          key: key,
          oldValue: element.old_values[key] || '',
          newValue: element.new_values[key] || '',
        });
      }
    });

    console.log(constructedContent, '====constructedContent');
    return constructedContent.length ? constructedContent : null;
  }

  onChangeOrganization(data: any) {
    this.selectedOrganisation = data?.orgId;

    this.dashboardElements.forEach((item: any) => {
      if (item.type.toLowerCase() === data?.option) {
          item.selectedOrg = data?.orgId
      }
    })
    this.getDashboardComparisionData();
  }

  getActivityTrackerData(tabName = 'Org Updates') {
    this.isLoading = true;
    this.dashboardSrvc
      .activityTracerData(
        this.startDate,
        this.endDate,
        1,
        tabName == 'My Updates'
      )
      .subscribe(
        (data: any) => {
          this.isLoading = false;
          const response = data.body.responseBody;

          // TODO Remove slicing once the pagination is fixed
          const trackerData = response.results
            .map((ele: any, index: number) => {
              ele.sno = index + 1;
              ele.module = ele.table_name || '-';
              ele.action =
                ele.action === 'u'
                  ? 'Updated'
                  : ele.action === 'd'
                  ? 'Deleted'
                  : ele?.action === 'i'
                  ? 'Insert'
                  : '-';
              ele.changed_by = ele?.changed_by?.full_name || '-';
              const changedValues = this.getContentMapping(ele);

              ele.previous_state = changedValues
                ? changedValues
                    .map((value) => {
                      const oldVal =
                        typeof value.oldValue === 'object'
                          ? JSON.stringify(value.oldValue)
                          : value.oldValue;
                      return `${value.key} : ${oldVal}`;
                    })
                    .join('\n')
                : '-';

              ele.current_state = changedValues
                ? changedValues
                    .map((value) => {
                      const newVal =
                        typeof value.newValue === 'object'
                          ? JSON.stringify(value.newValue)
                          : value.newValue;
                      return `${value.key} : ${newVal}`;
                    })
                    .join('\n')
                : '-';

              ele.modified_date =
                moment(ele?.modified_datetime).format(DATE_FORMAT_YYYY_MM_DD) ||
                '-';

              return ele;
            });

          this.dashboardElements[0].data = trackerData;
          this.cdr.detectChanges();
        },
        (err: any) => {
          this.isLoading = false;
          console.error(err, 'error in dashboard tracker');
          this.cdr.markForCheck();
        }
      );
  }

  getDashboardComparisionData() {
    let selectedOrg
    this.dashboardElements.forEach((element: any) => {
      if (this.option === element.type.toLowerCase()) {
        selectedOrg = element.selectedOrg
      }
    })

    this.isLoading = true;
    this.dashboardSrvc
      .getComparisionsData(
        this.option,
        this.currentAndPreviousDates['currentStartDate'],
        this.currentAndPreviousDates['currentEndDate'],
        this.currentAndPreviousDates['previousStartDate'],
        this.currentAndPreviousDates['previousEndDate'],
        selectedOrg || 0
      )
      .subscribe(
        (data: any) => {
          this.isLoading = false;
          const response = data.body.responseBody;

          if (this.option === 'organization') {
            this.dashboardElements[6].data = { ...response };
          } else {
            this.dashboardElements.forEach((element: any) => {
              if (this.option === element.type.toLowerCase()) {
                element.data = { ...response };
              } else {
                element.data = {};
              }
            });
          }

          this.cdr.detectChanges();
          this.cdr.markForCheck();
        },
        (err: any) => {
          this.isLoading = false;
          console.error(err, 'Error fetching dashboardData.');
          this.cdr.markForCheck();
        }
      );
  }

  sideBarToggler() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  getPreviousAndCurrentDates() {
    let previousEndDate = moment(
      this.currentAndPreviousDates['currentEndDate']
    );
    let diff = moment(this.endDate).diff(this.startDate, 'days');
    let currentStartAndPreviousEndDates = moment(previousEndDate).subtract(
      diff,
      'days'
    );
    diff == 0 ? (diff = 1) : diff;
    let previousStartDate = moment(currentStartAndPreviousEndDates).subtract(
      diff,
      'days'
    );

    this.currentAndPreviousDates = {
      ...this.currentAndPreviousDates,
      currentStartDate: moment(currentStartAndPreviousEndDates)
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
      previousEndDate: moment(currentStartAndPreviousEndDates)
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
      previousStartDate: moment(previousStartDate)
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
      currentEndDate: moment(this.currentAndPreviousDates['currentEndDate'])
        .add(1, 'day')
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS),
    };
  }

  onSegmentSelected(event) {
    const value = event.segmentValue;
    const currentDate = moment()
      .startOf('day')
      .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
    this.endDate = currentDate;
    this.currentAndPreviousDates = {
      ...this.currentAndPreviousDates,
      currentEndDate: currentDate,
    };

    if (value === 'Today') {
      this.startDate = currentDate;
      this.selectedDateFilter = 'day';
    } else if (value === '7d') {
      this.startDate = moment()
        .subtract(7, 'days')
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      this.selectedDateFilter = '7 days';
    } else if (value === '2w') {
      this.startDate = moment()
        .subtract(14, 'days')
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      this.selectedDateFilter = '2 weeks';
    } else if (value === '1m') {
      this.startDate = moment()
        .subtract(1, 'month')
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      this.selectedDateFilter = 'month';
    } else if (value && value.startDate && value.endDate) {
      this.startDate = value.startDate
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      this.endDate = value.endDate
        .startOf('day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      this.currentAndPreviousDates = {
        ...this.currentAndPreviousDates,
        currentEndDate: this.endDate,
      };
      this.selectedDateFilter = 'selected days';
    }

    this.dashboardElements.forEach((element) => {
      element.selectedDateFilter = this.selectedDateFilter;
    });

    this.getPreviousAndCurrentDates();
    if (this.option === 'activity tracker') {
      this.getActivityTrackerData();
    } else {
      this.option && this.getDashboardComparisionData();
    }
  }

  togglePanel(index: number, option: string) {
    let selectedOption = option.toLowerCase();
    this.currentPanel = option;

    if (selectedOption === 'organization data by industry') {
      this.downloadText = 'Download Organization';
    } else if (selectedOption === 'activity tracker') {
      this.downloadText = 'Download Activity';
    } else {
      this.downloadText = `Download ${option}`;
    }

    this.option =
      selectedOption === 'organization data by industry'
        ? 'organization'
        : selectedOption;

    if (this.option == 'finance') {
      const currentEndDate = moment(
        this.currentAndPreviousDates['currentEndDate']
      )
        .add(1, 'day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const previousEndDate = moment(
        this.currentAndPreviousDates['previousEndDate']
      )
        .add(1, 'day')
        .format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);

      this.currentAndPreviousDates = {
        ...this.currentAndPreviousDates,
        currentStartDate: this.currentAndPreviousDates['currentStartDate'],
        previousStartDate: this.currentAndPreviousDates['previousStartDate'],
        currentEndDate: currentEndDate,
        previousEndDate: previousEndDate,
      };
    }
    if (!this.dashboardElements[index].isPanelOpened) {
      this.isLoading = true;

      if (option === 'Activity Tracker') {
        this.getActivityTrackerData();
      } else {
        this.getDashboardComparisionData();
      }
    }

    this.dashboardElements.forEach((element: any, i: number) => {
      element.isPanelOpened = i === index ? !element.isPanelOpened : false;
    });

    this.cdr.detectChanges();
  }

  onChangeTab(event: any) {
    this.getActivityTrackerData(event);
  }

  captureScreenshotAsPDF() {
    this.isLoading = true;
    const accordionId = this.currentPanel;
    const element = document.getElementById(accordionId);

    if (element) {
      html2canvas(element)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');

          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210;
          const pageHeight = 295;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (imgHeight > pageHeight) {
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, pageHeight);
          } else {
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          }

          this.isLoading = false;

          pdf.save(`${accordionId}.pdf`);
          this.cdr.detectChanges();
        })
        .catch((error) => {
          this.isLoading = false;
          console.error('Screenshot capture failed:', error);
          this.cdr.detectChanges();
        });
    } else {
      this.isLoading = false;
      this.utilService.errorNotification(
        'No data to download. Please select one of the option and try again.'
      );
      this.cdr.detectChanges();
    }
  }

  downloadScreenshot() {
    const fileNamePrefix = 'activity_tracker_data';

    if (this.currentPanel === 'Activity Tracker') {
      const dataSource = this.dashboardElements[0].data;
      if (this.dashboardElements[0].data?.length) {
        this.downloadData = dataSource.map((item: any) => {
          return {
            'S.No': item?.sno,
            Module: item?.module,
            Action: item?.action,
            'Changed by': item?.changed_by,
            'Previous state': item?.previous_state,
            'Current state': item?.current_state,
            'Modified date': item?.modified_date,
          };
        });

        this.exportService.exportExcel(
          this.downloadData,
          `${fileNamePrefix}_${convertDate()}_${new Date().getTime()}`
        );
      } else {
        this.utilService.errorNotification(
          'There is no data to download. Please check again.'
        );
      }
    } else {
      this.captureScreenshotAsPDF();
    }
  }
}
