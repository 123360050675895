export const URL = {
    ACCOUNT: {
        LOGIN: '/authenticate/1.1.0',
        LOGOUT: '/logout',
        FORGOT_PWD: '/notifyPasswordReset',
        RESET_PWD: '/resetPassword',
        CHANGE_PWD: '/changePassword',
        MENU: '/getMenus',
        GET_USER_ORG_LIST: '/getUserOrgList',
        LOGIN_WITH_ORG: '/loginWithOrg',
        NEW_MENU: "/users/app-menus",
        USER_ROLES: "/users/roles",
        PERMISSIONS: "/permissions/",
    },
    ADMIN: {
        LIST_ORGS: '/getAllOrgs',
        GET_ALL_MODULES: '/getAllModules',
        GET_ALL_COURSE_FRAMEWORKS: '/getAllCourseFrameworks',
        GET_ALL_STATES: '/getAllStatesAndDistricts',
        GET_ALL_VERTICALS: '/getAllIndVerticals',
        GET_ORG_BY_ID: '/getOrgById',
        GET_ADMIN: '/getAdmin',
        GET_ALL_MOUS: '/getAllMou',
        CREATE_OR_UPDATE_ORG: '/createOrUpdateOrg',
        CREATE_UPDATE_ADMIN: '/addOrEditAdmin',
        UPDATE_ORG_STATUS: '/updateStatus/orgStatus',
        UPDATE_ADMIN_STATUS: '/updateStatus/adminStatus',
        UPDATE_ORG_ADMIN_STATUS: '/updateStatus/orgAdminStatus',
        GET_ALL_ADMIN_ROLES: '/getAllAdminRoles',
        GET_ADMIN_ROLE: '/getAdminRole',
        CREATE_OR_UPDATE_ROLE: '/users/roles',
        DELETE_ROLE: '/user/roles',
        UPDATE_ADMIN_ROLE_STATUS: '/updateStatus/adminRoleStatus',
        UPDATE_ADMIN_ROLE_PERMISSIONS: '/updateStatus/adminRolePermissions',
        GET_ALL_USER_CATEGORIES: '/getAllUserCategories',
        UPDATE_USER_CATEGORY_STATUS: '/updateStatus/userCategory',
        GET_CATEGORY_ATTRIBUTES_ID: '/getCategoryAttributesById',
        GET_ALL_ORG_USERS: '/getAllOrgUsers',
        UPDATE_USER_STATUS: '/updateStatus/user',
        SAVE_LOGIN_CREDENTIALS: '/saveUserLoginCredentials',
        GET_CUST_ATTR_VALUES_BY_ID: '/getCustAttrValuesById',
        GET_ALL_INDUSTRY_VERTICALS: '/getAllIndustryVerticals',
        UPDATE_INDUSTRY_VERTICAL_STATUS: '/updateStatus/industryVertical',
        GET_ALL_BUSINESS_VERTICALS: '/getAllBusinessVerticals',
        UPDATE_BUSINESS_VERTICAL_STATUS: '/updateStatus/businessVertical',
        GET_ALL_PRODUCTS: '/getAllOrgProducts',
        UPDATE_PRODUCT_STATUS: '/updateStatus/product',
        GET_TAGS_BY_ORG_ID: '/getTagsByOrgId',
        CREATE_UPDATE_TAG: '/addOrEditTag',
        SEARCH_TAG : '/searchLevel3',
        DOWNLOAD_SAMPLE_FILE: '/bulk-upload/sampleFile',
        UPLOAD_FILE: '/bulk-upload',
        UPLOAD_FILES:'/upload',
        DOWNLOAD: '/download',
        EXPENSE_TYPE: '/expense-type',
    },
    SALES: {
        CREATE_UPDATE_LEAD: '/addOrEditSalesLead',
        GET_ALL_LEADS: '/getAllSalesLead',
        GET_LEAD_DETAILS: '/getSalesLeadDetails',
        GET_OPPORTUNITY_COUNTS: '/getSalesOpportunityCounts',
        GET_OPPORTUNITY_DETAILS: '/getSalesOpportunityDetails',
        GET_OPPORTUNITY_LIST: '/getSalesOpportunityList',
        UPDATE_OPPORTUNITY_STATUS: '/updateStatus/opportunityStatus',
        GET_CUSTOMER_COUNTS: '/getSalesCustomerCounts',
        GET_CUSTOMER_OPPORTUNITY_LIST: '/getSalesCustomerOpportunityList',
        GET_OPPORTUNITY_MOU_LIST: '/getSalesOpportunityMouList',
        GET_OPPORTUNITY_MOU_DETAILS: '/getSalesOpportunityMouDetails',
        UPDATE_CUSTOMER_STATUS: '/updateStatus/salesCustomerStatus',
        UPDATE_REASON_FOR_DROPPING: '/updateStatus/reasonForDropping',
        GET_CUSTOMER_SPOCS_LIST: '/getCustomerSpocsList',
        GET_CUSTOMER_DETAILS: '/getSalesCustomerDetails',
        GET_ACCOUNT_MANAGERS: '/getSalesAccountManagers',
        GET_CUSTOMER_GROUPS: '/getSalesCustomerGroups',
        GET_CUSTOMER_GROUPS_CUSTOMERS: '/getSalesCustomerGroupsCustomers',
        UPDATE_LEAD_STATUS: '/updateStatus/updateSalesLeadStatus',
        CREATE_UPDATE_CUSTOMER: '/addOrEditSalesCustomer',
        CREATE_UPDATE_CUSTOMER_GROUPS: '/addOrEditCustomerGroups',
        CREATE_UPDATE_SCHEME: '/addorEdit/salesScheme',
        UPLOAD_SALES_LEADS_DATA: '/uploadSalesLeadsData',
        LEADS: '/leads',
        OPPORTUNITY: '/opportunity',
        CUSTOMERS: '/customers'
    },
    CONTENT: {
        GET_COURSE_FRAMEWORK: '/getCourseFrameWork',
        GET_COURSE_FRAMEWORK_LABELS: '/getCourseFrameworkLabels',
        GET_ALL_COURSE_GROUPS: '/getAllCourseGroups',
        UPDATE_COURSE_GROUP_STATUS: '/updateStatus/courseGroup',
        GET_COURSE_FRAMEWORK_BY_ORG_ID: '/getCourseFrameworksByOrgId',
        URL_META_DATA: '/getUrlMetaData',
        GET_ALL_COURSE_VERSIONS: '/getAllCourseVersions',
        GET_ALL_COURSE_MODULES: '/getAllCourseModules',
        GET_ALL_COURSE_TOPICS: '/getAllCourseTopics',
        GET_ALL_COURSES: '/getAllCourses',
        CREATE_UPDATE_COURSE_GROUP: '/addOrEditCourseGroup',
        DELETE_COURSE_GROUP: '/delete/deleteCourseGroup',
        CREATE_UPDATE_COURSE_SUB_GROUP: '/addOrEditCourseSubGroup',
        GET_ALL_COURSE_SUB_GROUPS: '/getAllcourseSubGroups',
        UPDATE_COURSE_SUB_GROUP_STATUS: '/updateStatus/courseSubGroup',
        CREATE_UPDATE_COURSE_TOPIC: '/addOrEditCourseTopic',
        GET_ALL_COURSE_LEVEL2_LIST : '/getCourseLevel2',
        INSERT_UPDATE_COURSE_LEVEL2_LIST : '/saveOrUpdatecourseLevel2',
        UPDATE_COURSE_VERSION_STATUS: '/updateStatus/courseVersionStatus',
        UPDATE_COURSE_MODULE_STATUS: '/updateStatus/courseModuleStatus',
        UPDATE_COURSE_TOPIC_STATUS: '/updateStatus/courseTopicStatus',
        UPDATE_COURSE_LEVEL3_STATUS: '/updateStatusLevel3',
        DELETE_COURSE_VERSION_BY_ID: '/deleteCourseVersionById',
        DELETE_COURSE_LEVEL1_BY_ID: '/deleteCourseLevel1',
        DELETE_COURSE_LEVEL2_BY_ID: '/deleteCourseLevel2',
        DELETE_COURSE_LEVEL3_BY_ID: '/deleteCourseLevel3',
        UPDATE_COURSE_STATUS: '/updateStatus/courseStatus',
        DOWNLOAD_COURSE: '/downloadCourse',
        ASSESSMENT_METHODOLOGY_LIST: '/getAssessmentMethodologyList',
        CREATE_UPDATE_QUESTION_BANK: '/addOrEditQuestionBank',
        CREATE_UPDATE_QUESTION_PAPER: '/addOrEditQuestionPaper',
        QUESTION_COURSE_MODULE_MAPPING: '/getQuestionCourseModuleMapping',
        SAVE_QUESTION_COURSE_MODULE_MAPPING: '/saveQuestionCourseModuleMapping',
        SAVE_OPTIONS_DATA: '/saveOptionsData',
        SAVE_FILL_IN_THE_BLANK: '/saveFillInTheBlankData',
        SAVE_MATCH_THE_FOLLOWINGS: '/saveMatchTheFollowingData',
        SAVE_SUBJECTIVE_ANSWER_DATA: '/saveSubjectiveAnswerData',
        GET_QUESTION_BANK_LIST: '/getQuestionBankList',
        UPDATE_QUESTION_BANK_STATUS: '/updateQuestionBankStatus',
        UPDATE_QUESTION_PAPER_STATUS: '/updateQuestionPaperStatus',
        DELETE_QUESTION_BANK : '/deleteQuestionBank',
        DELETE_QUESTION: '/deleteQuestion',
        SAVE_KPI: '/saveKpiData',
        DELETE_KPI: '/deleteKpiData',
        LANGUAGE_LIST : '/questionLanguage',
        LANGUAGE : '/languages',
        SAVE_RUBRIC_DATA : '/saveOrUpdateRubric',
        DELETE_RUBRIC: '/deleteRubric',
        DELETE_MATCHING_LIST: '/saveMatchTheFollowingData',
        SCENARIO_BASED_QUESTION_MAPPING: '/scenarioBasedQuestionMapping',
        GET_RUBRIC_LIST : '/getRubricList',
        CONTENT_FILE_UPLOAD : '/fileUploadContent',
        GET_QUESTION_PAPER_LIST: '/getQuestionPaperList',
        DELETE_QUESTION_PAPER: '/deleteQuestionPaper',
        GET_QUESTION_PAPER_VERSION_LIST:'/get/questionPaperVersionList',
        ADD_EDIT_QUESTION_PAPER_VERSION: '/addoredit/questionPaperVersion',
        DELETE_QUESTION_PAPER_VERSION : '/delete/deleteQuestionPaperVersion',
        UPDATE_STATUS_QUESTION_PAPER_VERSION:'/updateStatus/questionPaperVersion',
        UPDATE_QUESTION_PAPER_EVALUATION_STATUS: '/updateStatus/questionPaperEvaluation',
        GET_QUESTIONS_MANUAL_IMPORT: '/questions/manual-import',
        QUESTION_PAPER_AS_DRAFT: '/questions/paper-mappings',
        GET_QUESTIONS_AUTOMATED_IMPORT: '/questions/automated-selection',
        GET_ALL_GEO_IDS:'/geography/pincodes'

    },
    NOTIFICATIONS: {
        GET_ALL_NOTIFICATIONS: '/notifications',
        MARK_AS_READ: '/notifications/mark-as-read',
        NOTIFICATION_SETTINGS: '/notifications/settings',
        NOTIFICATIONS_EVENTS: '/notifications/events',
        SEND_NOTIFICATION: '/sendNotification',
        SUBSCRIBE_TO_PUSH: '/pub-sub/subscribe',
    },
    FINANCE: {
        RATE_MASTER: '/rate-master',
        INVOICES: '/invoices',
        BILLING_ADDRESS: '/billing-address',
        RECEIPTS: '/receipts',
        BILLS: '/bills',
        PAYMENTS: '/payments'
    },
    OPERATIONS: {
        PROJECTS: '/projects',
        CANDIDATES: '/candidates',
        ASSESSMENT: '/assessments',
        EXECUTE: '/candidates/stakeholders/token',
        SEND_ACCESS: '/candidates/{assessment_id}/credentials/notify',
    },
    SEARCH: {
        RECENT_SEARCHES: '/search/recents',
        SEARCH_SUGGESTIONS: '/search/suggestions',
        SEARCH: '/search'
    },
    DASHBOARD: {
        ACTIVITY_TRACKER: '/dashboard/activity',
        COMPARISIONS: '/dashboard/comparisons',
        ANALYSIS: '/dashboard/analysis',
        OPERATIONS_GRAPHS: '/dashboard/graphs/operations',
  },
}
